import React, { Component } from "react";
import Modal from "../../../Components/Modal/Modal";
import Filters from "../Filters/Filters";
import CloseIcon from "@material-ui/icons/Close";
class FilterModal extends Component {
  render() {
    return (
      <Modal show={this.props.show}>
        <div className="text-right" onClick={this.props.close}>
          <CloseIcon />
        </div>
        <Filters
          models={this.props.models}
          searchQuery={this.props.searchQuery}
          onClickMakeModel={this.props.onClickMakeModel}
          onClickFuelModel={this.props.onClickFuelModel}
          onClickNoOwners={this.props.onClickNoOwners}
          onClickPriceRange={this.props.onClickPriceRange}
          appliedMakeModel={this.props.appliedMakeModel}
          appliedFuelType={this.props.appliedFuelType}
          appliedNoOwners={this.props.appliedNoOwners}
          appliedPriceRange={this.props.appliedPriceRange}
        />
      </Modal>
    );
  }
}

export default FilterModal;
