import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import cityReducer from "./store/reducers/city";
import languageReducer from "./store/reducers/language.reducer";

import thunk from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import ReactGA from "react-ga";
import snackbarReducer from "./store/reducers/snackbar.reducer";
import authReducer from "./store/reducers/userAuth";

const rootReducer = combineReducers({
  city: cityReducer,
  language: languageReducer,
  snackbar: snackbarReducer,
  auth: authReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
