import React from "react";
import { Switch, Route, Redirect } from "react-router";
import Homepage from "./Views/Homepage/Homepage";
import CarListings from "./Views/Car Listings/CarListings";
import CarListingsTest from "./Views/Car Listings/CarListings_test";
import Summary from "./Views/Summary/Summary";
import SummaryTest from "./Views/Summary/Summary_test";
import PaymentTest from "./Views/Summary/PaymentTest";
import ContactUs from "./Views/ContactUs/ContactUs";
import SellCar from "./Views/SellCar/SellCar";
import QuickBuy from "./Views/QuickBuy/QuickBuy";
import VideosList from "./Views/VideosList/VideosListing"
import MagazineList from "./Views/Magazines/MagazineList";

const Routes = (props) => {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/car_listings" component={CarListings} />
      <Route exact path="/summary/:makemodel/:id" component={Summary} />
      <Route exact path="/contact_us" component={ContactUs} />
      <Route exact path="/sell_car" component={SellCar} />
      <Route exact path="/quick_buy" component={QuickBuy} />
      <Route exact path="/quick_buy/car_listings" component={CarListingsTest} />
      <Route exact path="/quick_buy/summary/:makemodel/:id" component={SummaryTest} />
      <Route exact path="/payment/:makemodel/:id" component={PaymentTest} />
      <Route exact path = "/videos" component={VideosList}/>
      <Route exact path="/magazine" component={MagazineList}/>
      <Route path="/*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};

export default Routes;
