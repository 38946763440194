import React, { Component } from "react";
import axios from "axios";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import classes from "./Summary.module.css";
import Slideshow from "../../Components/Slideshow/Slideshow";
import Form from "./Form/Form";
import VehicleDescription from "./VehicleDescription/VehicleDescription";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

class Summary extends Component {
  state = {
    vehicle: null,
    vehicleImages: [],
    tabvalue: 0,
    loading: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    initGA("UA-176156592-1");
    PageView();
    this.setState({
      ...this.state,
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_SERVER +
        "/vehicles?_id=" +
        this.props.match.params.id
      )
      .then((response) => {
        console.log(response.data);
        if (
          response.data[0].brand + "_" + response.data[0].model !==
          this.props.match.params.makemodel
        ) {
          this.props.history.push("/");
        } else {
          this.setState({
            ...this.state,
            loading: false,
            vehicle: response.data[0],
            vehicleImages: response.data[0].vehicleImages,
          });
        }
      })
      .catch((err) => {
        this.props.history.push("/");
        console.log(err);
      });
  }

  handleTabChange = (e, newvalue) => {
    this.setState({
      ...this.state,
      tabvalue: newvalue,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div>
        <Header />
        <div className={classes.summary}>
          <div className="container-fluid pt-2">
            <div
              className={classes.backButton + " text-left py-4 px-lg-4"}
              style={{ color: "grey" }}
              onClick={this.goBack}
            >
              <ArrowBackIcon color="primary" />
              Go Back
            </div>
            <div className="row px-lg-5 pb-lg-3">
              <div className="col-lg-7 pl-lg-0">
                {this.state.loading ? (
                  <Skeleton variant="rect" width="100%" height="100%" />
                ) : (
                    <Slideshow data={this.state.vehicleImages} />
                  )}{" "}
              </div>
              <div
                className="mx-3 mx-lg-0 col-lg-5 p-3 text-left"
                style={{ backgroundColor: "white" }}
              >
                <div className={classes.triangle}></div>
                {this.state.vehicle && !this.state.loading ? (
                  <>
                    <div className={classes.title}>
                      {this.state.vehicle.brand +
                        " " +
                        this.state.vehicle.model +
                        " " +
                        this.state.vehicle.variant}
                    </div>
                    <div className={classes.price}>
                      <span>PRICE</span>
                      {Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })
                        .format(this.state.vehicle.sellCost)
                        .replace(/\.00/g, "")}
                    </div>
                    <div className={classes.stats}>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.regYear}
                      </span>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.kmDriven} {"km"}
                      </span>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.city}
                      </span>
                      <span className="px-2">
                        {this.state.vehicle.fuelType}
                      </span>
                    </div>
                  </>
                ) : (
                    <div>
                      <Skeleton width="80%" />
                      <Skeleton width="30%" />
                      <Skeleton width="50%" />
                    </div>
                  )}
                <Form vehicleId={this.props.match.params.id} />
              </div>
            </div>
            <div className="row pt-3 pt-lg-0 px-3 px-lg-5 pb-5 text-left"

            >
              {this.state.vehicle ? (
                <div className="col-xl-7 px-0 pr-xl-3">
                  <div className={classes.whiteCont + " p-3"}>
                    <p className={classes.description}>
                      {this.state.vehicle.description}
                    </p>
                    <VehicleDescription vehicle={this.state.vehicle} />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Summary;
