import React, { Component } from "react";
import classes from "./SellCar.module.css";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import banner from "../../Assets/Mock/sellyourcar.png";
import { TextField, Button } from "@material-ui/core";
import * as formValidations from "../../Components/FormValidation/FormValidation";
import axios from "axios";
import CallIcon from "@material-ui/icons/Call";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";
class SellCar extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    initGA("UA-176156592-1");
    PageView();
  }

  state = {
    name: "",
    email: "",
    mobile: "",
    enquiry: "",
    errorJson: {},
    success: null,
  };

  handleChange = (event) => {
    let stateObj = this.state;
    stateObj[event.target.name] = event.target.value;
    this.setState({
      ...stateObj,
      errorJson: {},
    });
  };

  formValidation = () => {
    let errorJson = {};
    let errors = false;
    if (formValidations.emptyValidation(this.state.name)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.name-empty",
      });

      errorJson.nameError = errorMessage;
      errors = true;
    }
    if (this.state.mobile.length !== 10) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.mobile-10-digit",
      });

      errorJson.mobileError = errorMessage;
      errors = true;
    }
    if (!formValidations.validateEmail(this.state.email)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.invalidEmail",
      });
      errorJson.emailError = errorMessage;
      errors = true;
    }
    this.setState({
      ...this.state,
      errorJson,
    });
    return errors;
  };

  onSubmit = () => {
    if (!this.formValidation()) {
      let data = {
        enquiryMessage: this.state.enquiry,
        enquiryType: "selling",
        email: this.state.email,
        contactNo: "+91" + this.state.mobile,
        contactName: this.state.name,
      };
      console.log(data);
      axios
        .post(process.env.REACT_APP_SERVER + "/enquiry", data)
        .then((response) => {
          let success = this.props.intl.formatMessage({
            id: "sell-page.success.message",
          });
          this.setState({
            ...this.state,
            success: success,
            name: "",
            email: "",
            mobile: "",
            enquiry: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(this.state.errorJson);
    }
  };

  render() {
    return (
      <>
        <Header />
        <div className="container-fluid" style={{ marginTop: "100px" }}>
          <div className="row mt-4">
            <div className="col-lg-7 p-4">
              <h1 className={classes.heading}>
                <FormattedMessage
                  id="sell-page.title"
                  defaultMessage="Sell your Car"
                  description="Sell your Car"
                />
              </h1>
              <div className={classes.line}></div>
              <img src={banner} alt="Sell Your Car" style={{ width: "100%" }} />
              <p className={classes.text + " mb-0"}>
                1. Call us and book an appointment on{" "}
                <a href="tel:+91 9360037217">9360037217</a>
              </p>
              <p className={classes.text + " mb-0"}>
                2. CARS4ALL will reach you, inspect and close the deal
              </p>
            </div>
            <div className="col-lg-5 p-sm-5">
              <div className="py-2">
                <TextField
                  value={this.state.name}
                  required
                  error={this.state.errorJson.nameError}
                  helperText={this.state.errorJson.nameError}
                  name="name"
                  onChange={this.handleChange}
                  color="primary"
                  label={this.props.intl.formatMessage({
                    id: "sell-page.name.label",
                  })}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="py-2">
                <TextField
                  value={this.state.email}
                  required
                  type="email"
                  error={this.state.errorJson.emailError}
                  helperText={this.state.errorJson.emailError}
                  name="email"
                  onChange={this.handleChange}
                  color="primary"
                  label={this.props.intl.formatMessage({
                    id: "sell-page.email-id.label",
                  })}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="py-2">
                <TextField
                  inputProps={{
                    maxlength: 10,
                  }}
                  value={this.state.mobile}
                  type="tel"
                  required
                  error={this.state.errorJson.mobileError}
                  helperText={this.state.errorJson.mobileError}
                  name="mobile"
                  onChange={this.handleChange}
                  color="primary"
                  label={this.props.intl.formatMessage({
                    id: "sell-page.mobile-number.label",
                  })}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="py-2">
                <TextField
                  inputProps={{
                    maxlength: 500,
                  }}
                  value={this.state.enquiry}
                  error={this.state.errorJson.enquiryError}
                  helperText={`${this.state.enquiry.length}/${500}`}
                  name="enquiry"
                  onChange={this.handleChange}
                  color="primary"
                  multiline
                  rows={3}
                  label={this.props.intl.formatMessage({
                    id: "sell-page.enquiry-message.label",
                  })}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              </div>
              <div className="py-2">
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  onClick={this.onSubmit}
                >
                  <FormattedMessage
                    id="sell-page.submit-enquiry-button"
                    defaultMessage="Submit an enquiry"
                    description="Submit an enquiry"
                  />
                </Button>
              </div>
              {/* <div className="py-2">
                <hr className={classes.myHr} />
                <div className={classes.seperator}>OR</div>
              </div>
              <Button
                fullWidth
                color="secondary"
                variant="contained"
                onClick={this.onSubmit}
              >
                Call Directly 9360037217
              </Button> */}
              {this.state.success ? (
                <div className={classes.success + " py-2"}>
                  {this.state.success}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Footer />
      </>
    );
  }
}

export default injectIntl(SellCar);
