import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

const Card = () => {
  return (
    <div className="py-2 col-sm-6 col-xl-4">
      <div className="card">
        <Skeleton variant="rect" width="100%" height="200px" />
        <div className="card-body">
          <Skeleton />
          <div className="ml-auto" style={{ width: "25%" }}>
            <Skeleton width="100%" />
          </div>
          <Skeleton width="70%" />
          <div className="ml-auto" style={{ width: "25%" }}>
            <Skeleton width="100%" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
