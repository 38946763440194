import React, { Component } from "react";
import classes from "./VehicleDescription.module.css";
import axios from "axios"
import { connect } from "react-redux";
import { openSnackbar } from "../../../store/actions/snackbar";
import { Typography, CircularProgress } from "@material-ui/core";
import { withRouter } from "react-router";

class PaymentDescriptionTest extends Component {

  state = {
    loading: false
  }

  componentDidMount() {
    if (!localStorage.getItem('token')) {
      this.props.history.push('/quick_buy')
    } else this.paymentAccepted();
  }

  paymentAccepted = () => {
    var config = {
      method: 'post',
      url: 'https://8ysxt8ql0d.execute-api.ap-south-1.amazonaws.com/dev/api/v1/quickbuy/' + this.props.vehicle._id + '/users/' + this.props.user._id,
      // url: 'https://8ysxt8ql0d.execute-api.ap-south-1.amazonaws.com/dev/api/v1/quickbuy/5febfb0362f9ad0008834087/users/5ff1b346478f80272777a178',
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    };
    this.setState({ ...this.state, loading: true })
    axios(config)
      .then((response) => {
        this.setState({ ...this.state, loading: false })

        if (response.data.status) {
          this.props.openSnackbar("Your Buy Request has been placed succesfully!", "success")
        } else
          this.props.openSnackbar("Failed to place your request!", "error")
      })
      .catch(function (error) {
        this.setState({ ...this.state, loading: false })
        this.props.openSnackbar("Failed to place your request!", "error")
      });
  }

  render() {
    let vehicle = this.props.vehicle;
    let data1 = [
      { property: "Account Holder Name", value: "MARVAR INDIA PRIVATE LIMITED" },
      { property: "Branch Name", value: "SRIPERUMBUDUR" },
      { property: "Account Number", value: "50200049551984" },
      { property: "IFSC Code", value: "HDFC0001254" },
    ];

    return (
      <div className={classes.parentDiv}>
        <div>
          <table className={classes.table}>
            <thead />
            <tbody>
              {data1.map((item) => {
                return (
                  <tr key={item.property}>
                    <td>{item.property}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {/* <p>Kindly do the payment in 3 days.</p> */}
          {this.state.loading ?
            <Typography style={{ paddingTop: '3em' }} align='left'>
              <CircularProgress color='primary' />
            </Typography>
            : <buttton onClick={() => this.props.history.push('/quick_buy/car_listings')} class="btn btn-success rounded-pill w-50 mt-5">Okay</buttton>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

const mdtp = dispatch => {
  return {
    openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity))
  }
}

export default withRouter(connect(mapStateToProps, mdtp)(PaymentDescriptionTest));
