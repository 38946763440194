import React, { Component } from "react";
import classes from "./Header.module.css";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Mock/CAR4ALL logo-wg.png";
import logo2 from "../../../Assets/Mock/CAR4ALL logo3.png";
import Dropdown from "../Dropdown/Dropdown";
import { connect } from "react-redux";
import { set_city } from "../../../store/actions/city";
import { setLanguage } from "../../../store/actions/language.actions";

import { FormattedMessage } from "react-intl";
import axios from "axios";
import { Badge, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Person, Phone, PhoneCallback } from '@material-ui/icons';
import { logout } from '../../../store/actions/auth';

class Header extends Component {
  state = {
    isNavScroll: false,
    forSale: 0,
    anchorEl: null,
    contact: null
  };

  handleChange = (value) => {
    this.props.setCity(value);
  };

  componentDidMount() {
    this.navbarStyling();
    axios.get(process.env.REACT_APP_SERVER + "/v1/quickbuy/counts").then(response => {
      console.log(response.data)
      this.setState({
        ...this.state,
        forSale: response.data.quickBuy.forSale,
        contact: response.data.settings.callUs
      })
    }).catch(err => {
      console.log(err)
    })
  }

  navbarStyling() {
    document.addEventListener("scroll", () => {
      let isNavScroll = window.scrollY > 50;
      if (isNavScroll !== this.state.isNavScroll) {
        this.setState({ ...this.state, isNavScroll: isNavScroll });
      }
    });
  }

  handleClick = (event) => {
    this.setState({ ...this.state, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ ...this.state, anchorEl: null });
  };

  handleLogout = () => {
    this.props.logout()
  }

  render() {
    let bgClass = null;
    let linkClass = null;
    let brandImgSrc = null;
    let logoTextClass = null;
    let button = null;
    let badgeContent = null;
    new Date().getTime() > 1585679400000 ? badgeContent = "new" : badgeContent = this.state.forSale


    if (this.state.isNavScroll || this.props.page !== "home") {
      bgClass = classes.bgReg;
      linkClass = classes.linkClass;
      brandImgSrc = logo2;
      logoTextClass = classes.logoTextreg;
    } else {
      bgClass = classes.bgInit;
      linkClass = classes.linkClassInit;
      brandImgSrc = logo;
      logoTextClass = classes.logoText;
    }

    let options = [{ value: "Chennai" }];
    if (this.props.cityArray.length) {
      options = this.props.cityArray.map((city) => {
        return { value: city };
      });
    }
    return (
      <div
        className={"navbar fixed-top navbar-expand-md navbar-light " + bgClass}
        style={{ transition: "all 0.5s" }}
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              alt="cars4all logo"
              src={brandImgSrc}
              style={{ width: "80px", display: "inline-block" }}
            />
            <div className={logoTextClass}>C A R S 4 A L L</div>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className={classes.anchor + " navbar-nav ml-auto"}>

              <Badge style={{ color: 'white' }} color='secondary' badgeContent={badgeContent}>
                <Link to="/quick_buy">
                  <li className={"nav-item  " + classes.quickBuyBtn}>
                    <FormattedMessage
                      id="quick_buy.title"
                      defaultMessage="Quick Buy"
                      description="Quick Buy"
                    />
                  </li>
                </Link>
              </Badge>

              <a href="/#hiring">
                <li className={"nav-item  " + linkClass}>
                  <FormattedMessage
                    id="we-are-hiring.title"
                    defaultMessage="Careers"
                    description="Careers"
                  />
                </li>
              </a>
              <a href="/videos">
                <li className={"nav-item  " + linkClass}>
                  <FormattedMessage
                    id="we-are-videos"
                    defaultMessage="Videos"
                    description="Videos"
                  />
                </li>
              </a>
              <a href="/magazine">
                <li className={"nav-item  " + linkClass}>
                  <FormattedMessage
                    id="we-are-magazine"
                    defaultMessage="Magazine"
                    description="Magazine"
                  />
                </li>
              </a>
              <li className={"nav-item  " + linkClass}>
                <Dropdown
                  value={this.props.city}
                  options={options}
                  onOptionClick={this.handleChange}
                />
              </li>

              <li className={"nav-item  " + linkClass}>
                <Dropdown
                  value={this.props.language}
                  options={[
                    { value: "English" },
                    { value: "Tamil" },
                    { value: "Hindi" },
                  ]}
                  onOptionClick={(value) => {
                    this.props.setLanguage(value);
                  }}
                />
              </li>

              {this.props.user ? <div>
                <IconButton color='primary' onClick={this.handleClick}>
                  <Person />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  keepMounted
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <Link to='/quick_buy'><MenuItem><FormattedMessage
                    id="quick_buy.welcome"
                    defaultMessage="Welcome"
                    description="Welcome"
                  />, {this.props.user.name}</MenuItem></Link>
                  <MenuItem onClick={this.handleLogout}>

                    <FormattedMessage
                      id="quick_buy.logout"
                      defaultMessage="Logout"
                      description="Logout"
                    />
                  </MenuItem>
                </Menu>
              </div> : null}
              <a href={"tel:+91" + this.state.contact}>
                <li className={"nav-item d-flex align-items-center  " + classes.quickBuyBtn}>
                  <Phone fontSize='small' style={{marginRight:5}} />
                  {" "}
                  <FormattedMessage
                    id="quick_buy.callus"
                    defaultMessage="Call Us"
                    description="Call Us"
                  />
                </li>
              </a>

              {/* <li className={"nav-item  " + linkClass}>
                Login <i className="fas fa-sign-in-alt"></i>
              </li> */}
            </ul>
          </div>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    city: state.city.currentCity,
    cityArray: state.city.cityArray,
    language: state.language.languageFull,
    user: state.auth.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setCity: (city) => dispatch(set_city(city)),
    setLanguage: (language) => dispatch(setLanguage(language)),
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
