import React, { Component } from 'react';

import AWS from 'aws-sdk';

import { PageView, initGA } from '../../Components/Tracking/Tracking';
import Footer from '../../Components/Navbars/Footer/Footer';
import Header from '../../Components/Navbars/Header/Header';

import './Magazine.css';
// config
AWS.config.update({
  region: 'ap-south-1',
  accessKeyId: 'AKIAWCQ62PTAOUGMPFU3',
  secretAccessKey: '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',
});

class MagazineList extends Component {
  state = {
    videos: [],
  };
  fetchVideosFromS3 = async () => {
    const s3 = new AWS.S3();
    const params = {
      Bucket: 'vehicle-images-cars4all',
      Prefix: `${'magazine'}/`,
    };

    try {
      const response = await s3.listObjectsV2(params).promise();
      // Sort videos based on LastModified timestamp in descending order
      const sortedVideos = response.Contents.sort((a, b) => {
        return new Date(b.LastModified) - new Date(a.LastModified);
      });

      this.setState({ ...this.state, videos: sortedVideos });
    } catch (error) {
      console.error('Error fetching videos:', error);
      return [];
    }
  };

  componentDidMount() {
    this.fetchVideosFromS3();
    window.scrollTo(0, 0);
    initGA('UA-176156592-1');
    PageView();
  }
  splitName = (name) => {
    const parts = name?.split('-');
    return parts[1]?.toUpperCase();
  };
  render() {
    return (
      <div>
        <Header />
        <div className='container-fluid' style={{ padding: '100px' }}>
          <h1> Our Magazines </h1>
          <div className='video-grid2'>
            {this.state.videos.map((object) => (
              <div key={object.Key}>
                <div class='pdf-card'>
                  <embed
                    src={`https://vehicle-images-cars4all.s3.ap-south-1.amazonaws.com/${object.Key}`}
                    type='application/pdf'
                    width='100%'
                    height='150'
                  />
                  <div className='card-action'>
                    <a
                      href={`https://vehicle-images-cars4all.s3.ap-south-1.amazonaws.com/${object.Key}`}
                      download
                      class='download-link'
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target='_blank'
                    >
                      Download
                    </a>
                  </div>
                  <div>{this.splitName(object.Key)}</div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default MagazineList;
