import axios from "axios";
import * as actionTypes from "./actionTypes";

export const set_city = (city) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_CITY,
      city: city,
    });
  };
};

export const get_all_cities = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_SERVER + "/cities").then((response) => {
      dispatch({
        type: actionTypes.GET_CITIES,
        cityArray: response.data,
      });
    });
  };
};
