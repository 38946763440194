import React, { Component } from "react";
import classes from "./Hiring.module.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

class Hiring extends Component {
  render() {
    return (
      <div className={classes.hiring + " container"} id="hiring">
        <h1>
          <FormattedMessage
            id="we-are-hiring.title"
            defaultMessage="Careers"
            description="Careers"
          />
        </h1>
        {/* <p>Lorem Ipsum here the tag line will come</p> */}
        <div className={classes.positions}>
          <div className={classes.positionChip}>Web Developers</div>
          <div className={classes.positionChip}>Mobile App Developers</div>
          <div className={classes.positionChip}>Car Inspection Specialists</div>
          <div className={classes.positionChip}>Sales Engineers</div>
          <div className={classes.positionChip}>Accounts Assistants</div>
          <div className={classes.positionChip}>Liasion Officers</div>
          <div className={classes.tag}>
            <FormattedMessage
              id="we-are-hiring.send-profile"
              defaultMessage="Send Profile"
              description="Send Profile"
            />{" "}
            <a
              href="mailto: admin@cars4all.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>admin@cars4all.in</span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Hiring;
