import React, { Component } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";
import { get_all_cities } from "./store/actions/city";
import { connect } from "react-redux";

import { addLocaleData, IntlProvider } from "react-intl";
import tl from "react-intl/locale-data/tl";
import en from "react-intl/locale-data/en";
import hi from "react-intl/locale-data/hi";
import messages_hi from "./translations/hi.json";
import messages_en from "./translations/en.json";
import messages_tl from "./translations/tl.json";
import MySnackbar from "./Components/Snackbar/Snackbar";
import { check_auth_state } from "./store/actions/auth";

const messages = {
  hi: messages_hi,
  en: messages_en,
  tl: messages_tl,
};
const history = createBrowserHistory();

const trackingId = "UA-176156592-1";

ReactGA.initialize(trackingId);
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4CAF50",
    },
    secondary: {
      main: "#FFC857",
    },
  },
});

addLocaleData([...en, ...tl, ...hi]);

class App extends Component {
  componentDidMount() {
    this.props.getAllCities();
    this.props.checkAuth();
  }
  render() {
    return (
      <IntlProvider
        locale={this.props.language}
        messages={messages[this.props.language]}
      >
        <BrowserRouter history={history}>
          <ThemeProvider theme={theme}>
            <div className="App">
              <Routes />
            </div>
            <MySnackbar />

          </ThemeProvider>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCities: () => dispatch(get_all_cities()),
    checkAuth : () => dispatch(check_auth_state())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
