import React, { Component } from "react";
import { Chip } from "@material-ui/core";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";
const style = {
  color: "grey",
  //   textTransform: "uppercase",
  fontSize: "14px",
};
class FilterChips extends Component {
  handleClick = () => {};
  render() {
    return (
      <div className="p-2 text-left" style={{ backgroundColor: "white" }}>
        <span style={style}>
        <FormattedMessage
              id="filter.label.filter.applied"
              defaultMessage="Filters Applied"
              description="Filters Applied"
          />
        </span>
        {this.props.appliedMakeModel.map((item) => {
          return (
            <span className="p-1">
              <Chip
                variant="outlined"
                size="small"
                color="primary"
                label={item}
                key={item}
                onClick={() =>
                  this.props.onClickMakeModel(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                onDelete={() =>
                  this.props.onClickMakeModel(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
              />
            </span>
          );
        })}
        {this.props.appliedFuelType.map((item) => {
          return (
            <span className="p-1">
              <Chip
                variant="outlined"
                size="small"
                color="primary"
                label={item}
                key={item}
                onClick={() =>
                  this.props.onClickFuelModel(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                onDelete={() =>
                  this.props.onClickFuelModel(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                // style={{ color: "white" }}
              />
            </span>
          );
        })}
        {this.props.appliedNoOwners.map((item) => {
          return (
            <span className="p-1">
              <Chip
                variant="outlined"
                size="small"
                color="primary"
                label={item + (item === "1" ? " Owner" : " Owners")}
                key={item}
                onClick={() =>
                  this.props.onClickNoOwners(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                onDelete={() =>
                  this.props.onClickNoOwners(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                // style={{ color: "white" }}
              />
            </span>
          );
        })}

        {this.props.appliedPriceRange.map((item) => {
          return (
            <span className="p-1">
              <Chip
                variant="outlined"
                size="small"
                color="primary"
                label={item}
                key={item}
                onClick={() =>
                  this.props.onClickPriceRange(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                onDelete={() =>
                  this.props.onClickPriceRange(
                    { target: { checked: false } },
                    { name: item }
                  )
                }
                // style={{ color: "white" }}
              />
            </span>
          );
        })}
      </div>
    );
  }
}

export default FilterChips;
