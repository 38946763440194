import React, { Component } from "react";
import classes from "./VehicleDescription.module.css";
import { Rating } from "@material-ui/lab";

class VehicleDescriptionTest extends Component {
  render() {
    let vehicle = this.props.vehicle;
    let data1 = [
      { property: "Brand", value: vehicle.brand },
      { property: "Model", value: vehicle.model },
      { property: "Variant", value: vehicle.variant },
      { property: "Color", value: vehicle.color },
      { property: "Manufacturing Year", value: vehicle.manfYear }
    ];

    let data2 = [
      { property: "Fuel Type", value: vehicle.fuelType },
      { property: "Transmission Type", value: vehicle.transmissionType },
      { property: "Kms Driven", value: vehicle.kmDriven },
      { property: "Previous Owners", value: vehicle.noOfOnwers },
      { property: "Registration Year", value: vehicle.regYear }
    ];
    let data3 = [
      { property: "Insurance", value: vehicle.insurance },
      {
        property: "Fitness upto",
        value: (
          <Rating
            style={{ color: "#4caf50" }}
            size="small"
            name="Vehicle Rating"
            value={parseInt(vehicle.fitnessUpto)}
            readOnly
          />
        ),
      },
    ];

    return (
      <div className={classes.parentDiv}>
        <div className="table-responsive">
          <table className={`${classes.table} table`}>
            <thead />
            <tbody>
              {data1.map((item) => {
                return (
                  <tr key={item.property}>
                    <td>{item.property}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="table-responsive">
          <table className={`${classes.table} table`}>
            <thead />
            <tbody>
              {data2.map((item) => {
                return (
                  <tr key={item.property}>
                    <td>{item.property}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* <div>
          <table className={classes.table}>
            <thead />
            <tbody>
              {data3.map((item) => {
                return (
                  <tr key={item.property}>
                    <td>{item.property}</td>
                    <td>{item.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    );
  }
}

export default VehicleDescriptionTest;
