import React, { Component } from "react";
import classes from "./Footer.module.css";
import CopyrightIcon from "@material-ui/icons/Copyright";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";
class Footer extends Component {
  render() {
    return (
      <div className={classes.footer}>
        <div className="container py-5">
          <div className="row text-left">
            <div className="mb-4 col-sm-6 col-md-4 col-lg-3">
              <h3>CARS4ALL</h3>
              <div className={classes.mydiv} />
              <p>
                <MailOutlineIcon fontSize="small" />
                <a
                  href="mailto: admin@cars4all.in"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  admin@cars4all.in
                </a>
              </p>
              <p>
                <PhoneIcon fontSize="small" />
                <a href="tel:+91 9360037217">+91 9360037217</a>
              </p>
              <div className={classes.social}>
                <div>
                  <a
                    href="https://www.facebook.com/advaithmarvar"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FacebookIcon />
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/in/cars4all-india-67b1921b6/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedInIcon />
                  </a>
                </div>
                <div>
                  <a
                    href="https://twitter.com/cars4all3"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <TwitterIcon />
                  </a>
                </div>
                <div>
                  <a
                    href="https://instagram.com/cars4all5?igshid=1sazol5nqdxhg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <InstagramIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="mb-4 col-sm-6 col-md-4 col-lg-3">
              <h3>
                <FormattedMessage
                  id="footer.quick-links"
                  defaultMessage="Quick Links"
                  description="Quick Links"
                />
              </h3>
              <div className={classes.mydiv} />
              <div className={classes.links}>
                <div>
                  <Link to="/contact_us">
                    <FormattedMessage
                      id="about-us.contact"
                      defaultMessage="Contact Us"
                      description="Contact Us"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/sell_car">
                    <FormattedMessage
                      id="landing.sell-your-car"
                      defaultMessage="Sell your Car"
                      description="Sell your Car"
                    />
                  </Link>
                </div>
                <div>
                  <Link to="/car_listings">Cars for sale in Chennai</Link>
                </div>
              </div>
            </div>
            <div className="mb-4 col-sm-6 col-md-4 col-lg-3">
              <h3>
                <FormattedMessage
                  id="about-us.title"
                  defaultMessage="About Us"
                  description="About Us"
                />
              </h3>
              <div className={classes.mydiv} />
              <div>
                <FormattedMessage
                  id="about-us.text"
                  defaultMessage="About Us"
                  description="About Us"
                />
              </div>
            </div>
            <div className="mb-4 col-sm-6 col-md-4 col-lg-3">
              <h3>
                <FormattedMessage
                  id="footer.road-map"
                  defaultMessage="Road Map"
                  description="Road Map"
                />
              </h3>
              <div className={classes.mydiv} />

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8085023009953!2d80.11715251485683!3d13.047858116730598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52618ad484473f%3A0x556f8d6fccbda18b!2sMarvar%20India%20pvt.ltd..%2C!5e0!3m2!1sen!2sin!4v1598862507848!5m2!1sen!2sin"
                width="100%"
                height="200"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="Marvar India"
              ></iframe>
            </div>
          </div>
        </div>
        Copyright <CopyrightIcon /> Marvar. All rights reserved
      </div>
    );
  }
}

export default Footer;
