import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    user: null,
    loginError: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_SUCCESS:
            return updateObject(state, {
                user: action.user,
            });

        default:
            return state;
    }
};

export default reducer;
