import React from "react";

const Dropdown = (props) => {
  return (
    <div className="dropdown">
      <div
        className={"dropdown-toggle " + props.classes}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {props.value}
      </div>
      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        {props.options.map((option) => {
          let selectClass =
            props.value === option.value ? { backgroundColor: "#eee" } : null;
          return (
            <div
              key={option.value}
              onClick={() => props.onOptionClick(option.value)}
              className={props.optionClass + " dropdown-item"}
              style={{ cursor: "pointer", ...selectClass }}
            >
              {option.value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Dropdown;
