import {
    Breadcrumbs,
    Dialog,
    DialogContent,
    Button,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import classes from "./Login.module.css";
import Standard from "../../../Components/FileUpload/DropZone";
import axios from 'axios';
import { connect } from 'react-redux';
import { openSnackbar } from '../../../store/actions/snackbar';
import { get } from 'lodash';
import successSvg from '../../../Assets/QuickBuy/Success.svg'
import { login } from '../../../store/actions/auth';
import { FormattedMessage } from 'react-intl';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const fields = [
    { name: 'name', label: 'Name', type: 'text', xs: 12, required: true },
    { name: 'mobileNo', label: 'Mobile No.', type: 'phone', xs: 12, required: true },
    { name: 'alternateMobileNo', label: 'Alternate Mobile No', type: 'text', xs: 12, required: false },
    { name: 'email', label: 'E-mail Id', type: 'email', xs: 12, required: true },
    { name: 'address', label: 'Address', type: 'text', xs: 12, required: true },
    { name: 'city', label: 'City', type: 'text', xs: 6, required: true },
    { name: 'pincode', label: 'Pincode', type: 'number', xs: 6, required: true }
]


const Register = (props) => {

    const [formdata, setFormdata] = useState({});
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false)

    const handleChange = (e) => {
        setError({})
        setFormdata({ ...formdata, [e.target.name]: e.target.value })
    }


    return (
        <Dialog open={props.open} onClose={props.handleClose}
            style={{ maxWidth: 500, margin: 'auto', overflowY: 'hidden' }}>
            {/* <DialogTitle>Register</DialogTitle> */}
            <DialogContent style={{ overflowY: 'hidden' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.title}>
                            <FormattedMessage
                                id="quick_buy.login"
                                defaultMessage="login"
                                description="login"
                            />
                        </div>

                    </Grid>
                    <Grid item xs={12}>
                        <PhoneInput
                            // disableDropdown
                            // onlyCountries={['om']}
                            // disabled={true}
                            country={"in"}
                            placeholder={"Enter Your mobile Number"}
                            value={formdata.mobileNo}
                            onChange={mobileNo => setFormdata({ ...formdata, mobileNo })}
                            enableSearch={true}
                            disableSearchIcon={true}
                            inputStyle={{
                                backgroundColor: "#EEF5EE",
                                border: "none",
                                color: "rgb(56,56,56)",
                                fontSize: "18px",
                                width: "100%",
                                border: "1px solid #4CAF50",
                                boxShadow: "none",
                                borderRadius: "2px",
                                paddingTop: '10px',
                                paddingBottom: '10px',
                                height: 50
                            }}
                            buttonStyle={{
                                backgroundColor: "transparent",
                                border: "none",
                            }}
                        />
                        {/* <PhoneInput
                            inputProps={{
                                name: 'Mobile Number',
                                required: true,
                                autoFocus: true,
                                fullWidth: true
                            }}
                            inputClass={classes.inputClass}
                            country={'in'}
                            value={formdata.mobileNo}
                            onChange={mobileNo => setFormdata({ ...formdata, mobileNo })}
                        /> */}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <TextField
                            style={{
                                backgroundColor: '#EEF5EE',
                                border: 'none',
                                marginBottom: '20px'
                            }}
                            variant='outlined'
                            fullWidth
                            label='Mobile Number'
                            name='mobileNo'
                            onChange={handleChange}
                            type='text'
                            required={true}
                            error={null}
                            value={formdata.mobileNo}
                        // helperText={error[field.name]}
                        />
                    </Grid> */}
                    <Grid item xs={12} justify='center'>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{
                                borderRadius: '50px',
                                width: '100%',
                                color: 'white'
                            }} onClick={() => props.handleLogin(formdata.mobileNo)}>
                            <FormattedMessage
                                id="quick_buy.login"
                                defaultMessage="login"
                                description="login"
                            />
                        </Button>
                        <p className={classes.register} onClick={props.handleRegister}>
                            <FormattedMessage
                                id="quick_buy.dont"
                                defaultMessage="Don't have an account? "
                                description="Register"
                            />
                            <FormattedMessage
                                id="quick_buy.register"
                                defaultMessage="Register"
                                description="Register"
                            />
                        </p>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity)),
        login: (user) => dispatch(login(user))
    }
}

export default connect(null, mapDispatchToProps)(Register);
