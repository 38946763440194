import React, { useState, useEffect } from "react";
import classes from "./QuickBuy.module.css";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import { connect } from "react-redux";
import svg1 from "../../Assets/QuickBuy/Group 1925.svg";
import svg2 from "../../Assets/QuickBuy/Group 1926.svg";
import svg3 from "../../Assets/QuickBuy/Group 1927.svg";
import svg4 from "../../Assets/QuickBuy/Group 1928.svg";
import svg5 from "../../Assets/QuickBuy/Group 1929.svg";
import Card from "./Card";
import { Link, Redirect, withRouter } from "react-router-dom";
import Register from "./Register/Register";
import Login from "./Login/Login";
import Otp from "./Otp/Otp";
import axios from "axios"
import { openSnackbar } from "../../store/actions/snackbar";
import { Tabs } from "@material-ui/core";
import { FormattedMessage } from 'react-intl';

const steps = [
    { svg: svg2, title: 'Create Account', description: 'Fill in your details and scan your aadhar for verification.' },
    { svg: svg3, title: 'Select a car', description: 'Get certified car of your choice.' },
    { svg: svg4, title: 'Payment', description: 'Make the payment for the car on the bank account of cars4all within 3 days' },
    { svg: svg5, title: 'Documents check', description: 'CARS4ALL agent will verify your documents(original) at your doorstep.' },
    { svg: svg1, title: 'Doorstep delivery', description: 'After documents verification car will be handed over to you at your doorstep.' }
];

const QuickBuy = (props) => {

    const [open, setOpen] = useState(false);
    const [otpOpen, setOtp] = useState(false);
    const [loginOpen, setLogin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mobileNumber, setMob] = useState('');
    const [value, setValue] = React.useState(0);
    const [cars, setCars] = useState([])
    const [isRegister, setRegister] = useState(false)
    const [formdata, setFormdata] = useState({})

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLogin = (mobileNumber) => {
        if (mobileNumber && mobileNumber.match(/^(\d{1,3}[- ]?)?\d{10}$/)) {
            setMob(mobileNumber)
            setOtp(true)
            setLogin(false)
        } else props.openSnackbar("Invalid Mobile Number", "error")

    }

    useEffect(() => {
        initGA("UA-176156592-1");
        PageView();
    }, []);

    useEffect(() => {
        console.log(props.user)
        if (localStorage.getItem('user')) {
            let user = JSON.parse(localStorage.getItem('user'))
            console.log(user.isMobileVerified)
            console.log(!user.isMobileVerified)
            if (!user.isMobileVerified) {
                console.log("IN IF")
                setOtp(true)
            } else {
                console.log("IN ELSE")
                props.history.push('/quick_buy/car_listings')
            }
        }
    }, [props.user]);

    useEffect(() => {
        axios.get(process.env.REACT_APP_SERVER + '/v1/quickbuy?status=for-sale').then(response => {
            console.log(response.data)
            setCars(response.data.data)
        }).catch(err => {
            console.log(err.response)
        })
    }, [])



    return (
        <>{props.user && props.user.isMobileVerified ? <Redirect to='/quick_buy/car_listings' /> :
            <>
                <Header />
                <div className={classes.quickBuy}>
                    <div className='container-fluid'>
                        <div className='row justify-content-center'>
                            {
                                steps.map(step => <Card step={step} />)
                            }
                        </div>
                    </div>
                </div>
                <div className={classes.banner}>
                    {props.user ?
                        <div>
                            <Link to='/quick_buy/car_listings'>
                                <div className={classes.btn}>
                                    Welcome, {props.user.name}
                                </div>
                            </Link>

                            <p className={classes.register}><span>Logout</span></p>
                        </div> :
                        <div>
                            <div className={classes.btn}
                                onClick={() => {
                                    setRegister(true)
                                    setOpen(!open)
                                }}>
                                <FormattedMessage
                                    id="quick_buy.register"
                                    defaultMessage="Register"
                                    description="Register"
                                />
                            </div>
                            <p className={classes.register}>
                                <FormattedMessage
                                    id="quick_buy.already"
                                    defaultMessage="Already have an Account? "
                                    description="Existing Account"
                                /> <Link><span onClick={() => {
                                    setRegister(false)
                                    setLogin(true)
                                }}>
                                    <FormattedMessage
                                        id="quick_buy.login"
                                        defaultMessage="Login"
                                        description="Login"
                                    /></span></Link></p>
                        </div>}
                    <div className='container pb-5'>
                        <Tabs
                            value={null}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                        >
                            {cars.map(car => {
                                return (
                                    <div className={classes.card2} onClick={() => setLogin(true)}>
                                        <div className={classes.imgContainer2}>
                                            <img src={car.vehicleImages[0]} />
                                        </div>
                                        <div className='text-left p-3 pr-4' style={{ width: '250px' }}>
                                            <div className={classes.price}> {Intl.NumberFormat("en-IN", {
                                                style: "currency",
                                                currency: "INR",
                                            })
                                                .format(car.sellCost)
                                                .replace(/\.00/g, "")}
                                            </div>
                                            <div className={classes.title}>{car.brand} {car.model} {car.variant}</div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Tabs>
                    </div>
                </div>

                {open ?
                    <Register open={open}
                        handleClose={() => {
                            setOpen(!open)
                            // setOtp(true)
                        }}
                        handleLogin={() => {
                            setOpen(false)
                            setLogin(true)
                        }}
                        handleNext={(formdata) => {
                            setFormdata(formdata)
                            setMob(formdata.mobileNo)
                            setOtp(true)
                        }}

                    />
                    : null}
                {otpOpen ?
                    <Otp
                        formdata={formdata}
                        isRegister={isRegister}
                        open={otpOpen}
                        handleClose={() => setOtp(!otpOpen)}
                        mobileNumber={props.user ? props.user.mobileNo : mobileNumber} />
                    : null}
                {loginOpen ?
                    <Login handleLogin={handleLogin} open={loginOpen} handleRegister={() => {
                        setLogin(false)
                        setOpen(true)
                    }} handleClose={() => setLogin(!loginOpen)} />
                    : null}

                <Footer />
            </>}
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        city: state.city.currentCity,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QuickBuy));
