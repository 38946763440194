import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Button, Typography, CircularProgress } from '@material-ui/core';
import classes from './Otp.module.css'
import OtpInput from 'react-otp-input'
import { Close } from '@material-ui/icons';
import { openSnackbar } from "../../../store/actions/snackbar";
import { connect } from "react-redux";
import axios from "axios";
import { login } from '../../../store/actions/auth';
import Resend from "react-otp-timer";
import { FormattedMessage } from 'react-intl';
import { get } from "lodash"

const Otp = (props) => {

    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState(null);
    // const [resend, setResend] = useState(false);

    const handleChange = (e) => {
        setOtp(e)
    }

    useEffect(() => {
        if (props.user || props.mobileNumber) {
            getOtp();
        }
    }, [props.user, props.mobileNumber])

    const getOtp = () => {
        // setResend(true)
        axios.post(process.env.REACT_APP_SERVER + "/auth/users/otp/" + props.mobileNumber, null,
            { headers: { 'content-type': 'application/json' } }).then(response => {
                if (response.data.success) {
                    props.openSnackbar('OTP has been sent successfully!', 'success')
                }
            }).catch(err => {
                console.log(err)
            })
    }

    const createAccount = (formdata) => {
        setLoading(true)

        axios.post(process.env.REACT_APP_SERVER + '/auth/signup', { ...formdata, isMobileVerified: true }).then(response => {
            setLoading(false)
            if (response.data.accessToken) {
                localStorage.setItem('token', response.data.accessToken)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                props.login(response.data.user)
                // props.handleStep();
                props.handleClose();
            } else {
                props.handleClose();
                props.openSnackbar('Failed to Create Account! Please try again.', 'error')
            }
        }).catch(err => {
            setLoading(false)
            if (err.response && err.response.data.error === 'Duplicate field value entered') {
                props.openSnackbar('Account with this mobile number already exists!', 'error')
            } else
                props.openSnackbar(get(err, 'response.data.message', 'Failed to Create Account! Please try again.'), 'error')
        })
    }

    const verifyOtp = () => {
        setLoading(true)
        axios.post(process.env.REACT_APP_SERVER + "/auth/users/otp/verify/" + props.mobileNumber + "/" + otp, null,
            { headers: { 'content-type': 'application/json' } }).then(response => {
                console.log(response.data)
                setLoading(false)
                if (response.data.success) {
                    if (!response.data.user_exists) {
                        if (props.isRegister) {
                            createAccount(props.formdata)
                        } else {
                            props.openSnackbar("Account does not exist! Please register instead.", "error");
                        }
                    } else {
                        localStorage.setItem('token', response.data.accessToken)
                        localStorage.setItem('user', JSON.stringify(response.data))
                        props.login(response.data)
                        props.openSnackbar('Logged in Succesfully!', 'success')
                        props.handleClose();

                    }

                } else {
                    props.handleClose();
                    props.openSnackbar('Failed to Create Account! Please try again.', 'error')
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                props.openSnackbar("Invalid OTP Entered!", "error")
            })
    }

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle style={{ textAlign: 'right' }}>
                <Close
                    fontSize='small'
                    style={{ cursor: 'pointer' }}
                    onClick={props.handleClose} />
            </DialogTitle>
            <DialogContent style={{ padding: 30, paddingTop: 0 }}>
                <div className='text-center'>
                    <p className={classes.title}>
                        <FormattedMessage
                            id="quick_buy.otp"
                            defaultMessage="Enter the code sent to your mobile number"
                            description="otp"
                        />
                        <span> {props.mobileNumber}</span>
                    </p>
                    <OtpInput
                        isInputSecure
                        containerStyle={{ justifyContent: 'center' }}
                        shouldAutoFocus={true}
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        separator={<span className="mr-3"> </span>}
                        inputStyle={{
                            zIndex: "10",
                            width: "60px",
                            height: "60px",
                            fontSize: "30px",
                            background: "#EEF5EE",
                            border: "none",
                            color: "black",
                            borderBottom: "2px solid #4caf50"
                        }}
                        focusStyle={{
                            outline: "none",
                        }}
                        hasErrored={false}
                        errorStyle={{
                            borderBottom: "3px solid red",
                        }}
                        isInputNum={true}
                    />
                    <p className={classes.resend}>
                        <FormattedMessage
                            id="quick_buy.didnt"
                            defaultMessage="Didn't get the code?"
                            description="otp"
                        />{" "}
                        <span onClick={getOtp}><FormattedMessage
                            id="quick_buy.resend"
                            defaultMessage="Resend OTP"
                            description="otp"
                        /></span>
                        {/* } */}

                    </p>
                    {loading ?
                        <Typography align='center'>
                            <CircularProgress color='primary' />
                        </Typography>
                        :
                        <Button
                            variant='contained'
                            color='primary'
                            style={{
                                borderRadius: '50px',
                                width: '100%',
                                color: 'white'
                            }} onClick={verifyOtp}>
                            <FormattedMessage
                                id="quick_buy.verify"
                                defaultMessage="Verify OTP"
                                description="otp"
                            /></Button>}
                </div>
            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = (state) => {
    return {
        city: state.city.currentCity,
        user: state.auth.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity)),
        login: (user) => dispatch(login(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Otp);;
