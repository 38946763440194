export const vehicleCalculator = (
  array,
  makeModelFilter,
  appliedFuelType,
  appliedNoOwners,
  appliedPriceRange,
  city
) => {
  
  let newArray = [];

  if (makeModelFilter.length) {
    newArray = array.filter((item) => {
      if (
        makeModelFilter.some(
          (filter) => item.brand + " " + item.model === filter
        )
      ) {
        return item;
      }
    });
  } else newArray = array;

  if (appliedFuelType.length) {
    newArray = newArray.filter((item) => {
      if (appliedFuelType.some((filter) => item.fuelType === filter)) {
        return item;
      }
    });
  }

  if (appliedNoOwners.length) {
    newArray = newArray.filter((item) => {
      if (
        appliedNoOwners.some(
          (filter) =>
            item.noOfOnwers === filter ||
            (filter === "4 and above" && parseInt(item.noOfOnwers) >= 4)
        )
      ) {
        return item;
      }
    });
  }

  if (appliedPriceRange.length) {
    let priceRange = appliedPriceRange.map((range) => {
      let priceJson = priceJsonConverter(range);
      return priceJson;
    });
    newArray = newArray.filter((item) => {
      if (
        priceRange.some(
          (range) =>
            parseInt(item.sellCost) >= range.min &&
            parseInt(item.sellCost) <= range.max
        )
      )
        return item;
    });
  }

  newArray = newArray.filter((item) => item.status === "for-sale");
  newArray = newArray.filter((item) => item.city === city);

  return newArray;
};

const priceJsonConverter = (priceString) => {
  switch (priceString) {
    case "Rs0 - Rs1,50,000":
      return { min: 0, max: 150000 };
    case "Rs1,50,001 - Rs3,00,000":
      return { min: 150001, max: 300000 };
    case "Rs3,00,001 - Rs5,00,000":
      return { min: 300001, max: 500000 };
    case "Rs5,00,001 - Rs7,00,000":
      return { min: 500001, max: 700000 };
    case "Rs7,00,001 - Rs10,00,000":
      return { min: 700001, max: 1000000 };
    case "Rs10,00,001 and above":
      return { min: 1000001, max: 1000000000 };
    default:
      return { min: 0, max: 0 };
  }
};
