import React, { Component } from "react";
import classes from "./Homepage.module.css";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import Landing from "./Landing/Landing";
import Samples from "./Samples/Samples";
import WhyChooseUs from "./WhyChooseUs/WhyChooseUs";
import Hiring from "./Hiring/Hiring";
import Testimonials from "./Testimonials/Testimonials";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import { connect } from "react-redux";
import Carousel from "nuka-carousel";
import axios from "axios";

class Homepage extends Component {

  state = {
    brands: []
  }
  componentDidMount() {
    initGA("UA-176156592-1");
    PageView();
    this.getBrands();
  }

  getBrands = () => {
    axios.get(process.env.REACT_APP_SERVER + '/v1/brands').then(response => {
      console.log(response.data)
      this.setState({ ...this.state, brands: response.data.data })
    }).catch(err => {
      console.log(err)
    })
  }
  render() {
    return (
      <>
        <Header page="home" />
        <div className={classes.homepage}>
          <Landing city={this.props.city} />
          {/* <Samples /> */}
          <div className='container'>
            <div className='row'>
              <div className='col-2 d-flex align-items-center'>
                <div className={classes.brandsTitle}>Brands available</div>
              </div>
              <div className='col-10'>
                <Carousel
                  slideWidth='110px'
                  heightMode="max"
                  autoplay={true}
                  autoplayInterval={4000}
                  // slidesToShow={5}
                  wrapAround={true}
                  renderCenterLeftControls={null}
                  renderCenterRightControls={null}
                  // transitionMode='scroll3d'
                  pauseOnHover={false}
                  renderBottomCenterControls={false}
                >
                  {this.state.brands.map(brand => {
                    return (
                      brand.brandUrl ?
                        <div className='d-flex align-items-center' style={{ width: '100px', height: '100%' }}>
                          <img src={brand.brandUrl} width='100%' />
                        </div>
                        : null
                    )
                  })}
                </Carousel>
              </div>
            </div>
          </div>
          <WhyChooseUs />
          <Hiring />
          <Testimonials />
        </div>
        <Footer />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    city: state.city.currentCity,
  };
};

export default connect(mapStateToProps)(Homepage);
