import React, { Component } from "react";
import classes from "./SearchList.module.css";
import axios from "axios";
import { vehicleCalculator } from "./VehicleCalculator";
import { Link } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import Card from "../../../Components/Preloader/Card";

class SearchList extends Component {
  state = {
    vehicles: [],
    loading: false,
  };

  componentDidMount() {
    this.fetchCars();
  }

  fetchCars = (make, model) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    let url = process.env.REACT_APP_SERVER + "/vehicles";
    axios
      .get(url)
      .then((response) => {
        this.setState({
          ...this.state,
          vehicles: response.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    let vehicles = this.state.vehicles;
    vehicles = vehicleCalculator(
      vehicles,
      this.props.appliedMakeModel,
      this.props.appliedFuelType,
      this.props.appliedNoOwners,
      this.props.appliedPriceRange,
      this.props.city
    );
    return (
      <div className="row p-3">
        {this.state.loading ? (
          <>
            <Card />
            <Card />
            <Card />
          </>
        ) : (
          vehicles.map((vehicle) => {
            return (
              <div className="py-2 col-sm-6 col-xl-4" key={vehicle._id}>
                <div className={classes.myCard + " card"}>
                  <div className={classes.tag}>
                    <div className={classes.label}>Certified</div>
                    <div className={classes.border}></div>
                  </div>

                  <Link
                    to={
                      "/summary/" +
                      vehicle.brand +
                      "_" +
                      vehicle.model +
                      "/" +
                      vehicle._id
                    }
                  >
                    <img
                      className="card-img-top"
                      src={vehicle.vehicleImages[0]}
                      style={{
                        height: "200px",
                        borderBottom: "4px solid #4CAF50",
                      }}
                      alt="car"
                    />
                    <div className="card-body text-left pb-2">
                      <div className={classes.title}>
                        {vehicle.brand} {vehicle.model} {vehicle.variant}
                      </div>
                      <div className={classes.price}>
                        {Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                          .format(vehicle.sellCost)
                          .replace(/\.00/g, "")}
                      </div>
                      <div className={classes.stats}>
                        <span className={classes.borderRight + " px-2"}>
                          {vehicle.regYear}
                        </span>
                        <span className={classes.borderRight + " px-2"}>
                          {vehicle.kmDriven} {"km"}
                        </span>
                        <span className="px-2">{vehicle.fuelType}</span>
                      </div>
                      <div
                        className="text-right pt-2"
                        style={{ marginTop: "-5px" }}
                      >
                        <Rating
                          style={{ color: "#4caf50" }}
                          size="small"
                          name="Vehicle Rating"
                          value={parseInt(vehicle.fitnessUpto)}
                          readOnly
                        />
                        <span className={classes.ratingLabel}>
                          {vehicle.fitnessUpto}/5
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            );
          })
        )}
      </div>
    );
  }
}

export default SearchList;
