import React, { Component } from "react";
import classes from "./CarListings.module.css";
import Header from "../../Components/Navbars/Header/Header";
import SearchList from "./Search List/SearchList";
import Filters from "./Filters/Filters";
import queryString from "query-string";
import axios from "axios";
import Footer from "../../Components/Navbars/Footer/Footer";
import FilterModal from "./FilterModal/FilterModal";
import Backdrop from "../../Components/Backdrop/Backdrop";
import FilterChips from "./FilterChips/FilterChips";
import { initGA, PageView } from "../../Components/Tracking/Tracking";
import FilterListIcon from "@material-ui/icons/FilterList";
import { connect } from "react-redux";

class CarListings extends Component {
  state = {
    searchQuery: null,
    models: [],
    appliedMakeModel: [],
    appliedFuelType: [],
    showFilterModal: false,
    appliedNoOwners: [],
    appliedPriceRange: [],
  };

  componentDidMount() {
    this.getBrandModels();
    initGA("UA-176156592-1");
    PageView();
  }

  componentDidUpdate(prevProps) {
    if (this.props.city !== prevProps.city) this.getBrandModels();
  }
  toggleFilterModal = () => {
    this.setState({
      ...this.state,
      showFilterModal: !this.state.showFilterModal,
    });
  };

  onClickMakeModel = (e, obj) => {
    let appliedMakeModel = this.state.appliedMakeModel;
    if (e.target.checked) {
      appliedMakeModel.push(obj.name);
    } else {
      appliedMakeModel.splice(appliedMakeModel.indexOf(obj.name), 1);
    }
    this.setState({
      ...this.state,
      appliedMakeModel,
    });
  };

  onClickFuelModel = (e, obj) => {
    let appliedFuelType = this.state.appliedFuelType;
    if (e.target.checked) {
      appliedFuelType.push(obj.name);
    } else appliedFuelType.splice(appliedFuelType.indexOf(obj.name), 1);
    this.setState({
      ...this.state,
      appliedFuelType,
    });
  };

  onClickNoOwners = (e, obj) => {
    let appliedNoOwners = this.state.appliedNoOwners;
    if (e.target.checked) {
      appliedNoOwners.push(obj.name);
    } else appliedNoOwners.splice(appliedNoOwners.indexOf(obj.name), 1);
    this.setState({
      ...this.state,
      appliedNoOwners,
    });
  };

  onClickPriceRange = (e, obj) => {
    let appliedPriceRange = this.state.appliedPriceRange;
    if (e.target.checked) {
      appliedPriceRange.push(obj.name);
    } else appliedPriceRange.splice(appliedPriceRange.indexOf(obj.name), 1);
    this.setState({
      ...this.state,
      appliedPriceRange,
    });
  };

  getBrandModels = () => {
    let searchQuery = queryString.parse(this.props.location.search);
    axios
      .get(process.env.REACT_APP_SERVER + "/make?city=" + this.props.city)
      .then((response) => {
        if (response.status === 200) {
          let models = [];
          let appliedMakeModel = [];
          response.data.map((item) => {
            item.models.map((model) => {
              models.push(item.brand + " " + model);
              let searchString = "";
              if (searchQuery.model) {
                if (searchQuery.model === model) {
                  appliedMakeModel.push(item.brand + " " + model);
                }
              } else {
                searchString = searchQuery.make;
                if ((item.brand + " " + model).indexOf(searchString) > -1) {
                  appliedMakeModel.push(item.brand + " " + model);
                }
              }
            });
          });
          this.setState({
            ...this.state,
            models,
            appliedMakeModel,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <div style={{ backgroundColor: "#eee" }}>
          <Header />
          <div className={classes.container + " container-fluid"}>
            <div className="row">
              <div className="col-lg-3 d-none d-lg-block">
                <div className={classes.filter + " mb-5"}>
                  <Filters
                    models={this.state.models}
                    searchQuery={this.props.location.search}
                    onClickMakeModel={this.onClickMakeModel}
                    onClickFuelModel={this.onClickFuelModel}
                    onClickNoOwners={this.onClickNoOwners}
                    onClickPriceRange={this.onClickPriceRange}
                    appliedMakeModel={this.state.appliedMakeModel}
                    appliedFuelType={this.state.appliedFuelType}
                    appliedNoOwners={this.state.appliedNoOwners}
                    appliedPriceRange={this.state.appliedPriceRange}
                  />
                </div>
              </div>
              <div className="col-lg-9 col-12 mb-5">
                <FilterChips
                  onClickMakeModel={this.onClickMakeModel}
                  onClickFuelModel={this.onClickFuelModel}
                  onClickNoOwners={this.onClickNoOwners}
                  onClickPriceRange={this.onClickPriceRange}
                  appliedMakeModel={this.state.appliedMakeModel}
                  appliedFuelType={this.state.appliedFuelType}
                  appliedNoOwners={this.state.appliedNoOwners}
                  appliedPriceRange={this.state.appliedPriceRange}
                />
                <div className={classes.content}>
                  <SearchList
                    searchQuery={this.props.location.search}
                    appliedMakeModel={this.state.appliedMakeModel}
                    appliedFuelType={this.state.appliedFuelType}
                    appliedNoOwners={this.state.appliedNoOwners}
                    appliedPriceRange={this.state.appliedPriceRange}
                    city={this.props.city}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.filterButton + " d-lg-none"}>
            <div className="container-fluid">
              {this.state.showFilterModal ? (
                <div className={classes.applyFilters + " row p-3"}>
                  <div className={"col-12"} onClick={this.toggleFilterModal}>
                    Apply Filters
                  </div>
                </div>
              ) : (
                <div className="row p-3">
                  {/* <div className={classes.borderRight + " col-6"}>Sort By</div> */}
                  <div onClick={this.toggleFilterModal} className="col-12">
                    <FilterListIcon color="primary" fontSize="small" />
                    Filters
                  </div>
                </div>
              )}
            </div>
          </div>

          <Footer />
        </div>

        {this.state.showFilterModal ? (
          <FilterModal
            show={this.state.showFilterModal}
            models={this.state.models}
            searchQuery={this.props.location.search}
            onClickMakeModel={this.onClickMakeModel}
            onClickFuelModel={this.onClickFuelModel}
            onClickNoOwners={this.onClickNoOwners}
            onClickPriceRange={this.onClickPriceRange}
            appliedMakeModel={this.state.appliedMakeModel}
            appliedFuelType={this.state.appliedFuelType}
            appliedNoOwners={this.state.appliedNoOwners}
            appliedPriceRange={this.state.appliedPriceRange}
            close={this.toggleFilterModal}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    city: state.city.currentCity,
  };
};

export default connect(mapStateToProps)(CarListings);
