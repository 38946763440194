import React from "react";
import classes from "./Testimonials.module.css";
import logo from "../../../Assets/Mock/CAR4ALL logo-rg.png";

const testimonial = (props) => {
  return (
    <div className="bubble">
      <blockquote>
        {props.quote}
        <br />
        <span className={classes.avatar}>
          <img src={props.img} alt={"author"} />
        </span>
        <span>- {props.cite}</span>
      </blockquote>
    </div>
  );
};

export default testimonial;
