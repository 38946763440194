import React from 'react';
import classes from './QuickBuy.module.css'

const Card = ({ step }) => {
    return (
        <div className={classes.card + ' d-inline-block'}>
            <div className={classes.imgContainer}>
                <img src={step.svg} />
            </div>
            <div className='card-body' >
                <h3>{step.title}</h3>
                <p>{step.description}</p>
            </div>
        </div>
    );
}

export default Card;
