import React, { Component } from "react";
import classes from "./ContactUs.module.css";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import RoomIcon from "@material-ui/icons/Room";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import { PageView, initGA } from "../../Components/Tracking/Tracking";

class ContactUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    initGA("UA-176156592-1");
    PageView();
  }
  render() {
    return (
      <>
        <Header />
        <div className={classes.contactUs}>
          <div className="container">
            <h1>Contact Us</h1>
              <div className={classes.info + " col-md-12 p-7"}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.8085023009953!2d80.11715251485683!3d13.047858116730598!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52618ad484473f%3A0x556f8d6fccbda18b!2sMarvar%20India%20pvt.ltd..%2C!5e0!3m2!1sen!2sin!4v1598862507848!5m2!1sen!2sin"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  style={{ border: "0" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Marvar India"
                ></iframe>
                <p className="pt-4">
                  <RoomIcon color="primary" />
                  <a
                    href="https://marvar.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CARS4ALL
                  </a>
                  ,No 4/219, Block - 2, Ground floor, Alaka Palazzo,Mount Poonamallee
                  High Rd, Kattupakkam, Chennai-600056. Tamil Nadu, India.
                </p>
                <hr />
                <p>
                  <MailOutlineIcon color="primary" />
                  <a
                    href="mailto: info@marvar.in"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    info@marvar.in
                  </a>
                </p>
                <hr />
                <p>
                  <PhoneIcon color="primary" />
                  +91 9360037217
                </p>
                <hr />
              </div>
            </div>
          </div>
        <Footer />
      </>
    );
  }
}

export default ContactUs;