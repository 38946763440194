import React, { Component } from "react";
import { Slide } from "react-slideshow-image";
import classes from "./Slideshow.module.css";
import "react-slideshow-image/dist/styles.css";
import ImageGallery from "react-image-gallery";
import "./image-gallery.css";

class Slideshow extends Component {
  render() {
    let items = this.props.data.map((item) => {
      return { original: item, thumbnail: item };
    });
    return (
      <ImageGallery
        items={items}
        showPlayButton={false}
        autoPlay={true}
        showBullets={true}
        // additionalClass="mt-5 pt-5 pb-5 mb-5"
      />
      // <div className="slide-container my-4 my-lg-0">
      //   <Slide>
      //     {this.props.data.map((item,index) => {
      //       return (
      //         <div key={index} className="each-slide">
      //           <div
      //             className={classes.imgContainer}
      //             style={{
      //               backgroundImage: "url(" + item + ")",
      //             }}
      //           ></div>
      //         </div>
      //       );
      //     })}
      //   </Slide>
      // </div>
    );
  }
}

export default Slideshow;
