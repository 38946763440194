import {
    Breadcrumbs,
    Dialog,
    DialogContent,
    Button,
    DialogTitle,
    Grid,
    TextField,
    Typography,
    CircularProgress
} from '@material-ui/core';
import { NavigateNext } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import classes from "./Register.module.css";
import Standard from "../../../Components/FileUpload/DropZone";
import axios from 'axios';
import { connect } from 'react-redux';
import { openSnackbar } from '../../../store/actions/snackbar';
import { get } from 'lodash';
import successSvg from '../../../Assets/QuickBuy/Success.svg'
import { login } from '../../../store/actions/auth';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PhoneInput from "react-phone-input-2";

const fields = [
    { name: 'name', label: 'Name', type: 'text', xs: 12, required: true },
    { name: 'mobileNo', label: 'Mobile No.', type: 'phone', xs: 12, required: true, onBlur: true },
    { name: 'alternateMobileNo', label: 'Alternate Mobile No', type: 'text', xs: 12, required: false },
    { name: 'email', label: 'E-mail Id', type: 'email', xs: 12, required: false },
    { name: 'address', label: 'Address', type: 'text', xs: 12, required: true },
    { name: 'city', label: 'City', type: 'text', xs: 6, required: true },
    { name: 'pincode', label: 'Pincode', type: 'number', xs: 6, required: true }
]


const Register = (props) => {

    const [formdata, setFormdata] = useState({});
    const [step, setStep] = useState(1);
    const [error, setError] = useState({});
    const [files, setFiles] = useState([]);
    const [loading, setLoading] = useState(false)
    const [unique, setUnique] = useState(true)

    const handleChange = (e) => {
        setError({})
        setFormdata({ ...formdata, [e.target.name]: e.target.value })
    }

    const handleStep = (s) => {
        setStep(s)
    }

    const handleUpload = (newFiles) => {
        setFiles(files.concat(newFiles))
    }

    const handleStepOneSubmit = () => {
        let error = {},
            isError = false
        fields.map(field => {
            if (field.required && (!formdata[field.name] || formdata[field.name].length === 0)) {
                error[field.name] = "This field cannot be empty"
                isError = true;
            }
        })

        if (!unique) {
            error.mobileNo = "Mobile No. already exists!"
            isError = true
        }

        if (!isError && unique) {
            setLoading(true)
            props.handleNext(formdata);
            props.handleClose();
        } else setError(error)
    }

    const checkUnique = () => {
        axios.get(process.env.REACT_APP_SERVER + '/auth/users/mobileNo/' + formdata.mobileNo).then(response => {
            console.log(response.data)
            setUnique(response.data.unique)
            if (!response.data.unique) {
                setError({ ...error, mobileNo: "Account already exists!" })
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const sendSMS = (user) => {
        axios.post('https://43e6fswsn4.execute-api.ap-south-1.amazonaws.com/dev/message/sms', {
            to: '+' + user.mobileNo,
            body: 'Your Aadhar Card is verfied. Please proceed further and select your lovely car.'
        }).then(response => {
        }).catch(err => {
        })
    }

    useEffect(() => {
        if (localStorage.getItem('user')) {
            let user = localStorage.getItem('user')
            user.isVerified || user.aadharUrl ? setStep(1) : setStep(2)
        } else {
            setStep(1)
        }
    }, []);

    const handleStepTwoSubmit = async () => {
        if (files.length) {
            var data = new FormData();
            data.append('file', files[0]);
            var config = {
                method: 'post',
                url: 'https://kyc-api.aadhaarkyc.io/api/v1/ocr/aadhaar',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDY5MTA4MTcsIm5iZiI6MTYwNjkxMDgxNywianRpIjoiZDA2YWZjZTktMzhkMy00YzQ0LTgxMTktMDAyMmYwNDYyOTliIiwiZXhwIjoxOTIyMjcwODE3LCJpZGVudGl0eSI6ImRldi5nYXJpYWphZ3J1dGlAYWFkaGFhcmFwaS5pbyIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyIsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJyZWFkIl19fQ.6YCuyDhaFrotMFVRycJ12fqd8DLfNrVIhv4Ex5sSAuA',
                },
                data: data
            };
            setLoading(true)

            axios(config)
                .then((response) => {
                    if (response.data && response.data.status_code === 200) {
                        let aadhar = get(response, 'data.data.ocr_fields[0].aadhaar_number.value', '')
                        var data = JSON.stringify({ "id_number": aadhar });

                        var config2 = {
                            method: 'post',
                            url: 'https://kyc-api.aadhaarkyc.io/api/v1/aadhaar-validation/aadhaar-validation',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MDY5MTA4MTcsIm5iZiI6MTYwNjkxMDgxNywianRpIjoiZDA2YWZjZTktMzhkMy00YzQ0LTgxMTktMDAyMmYwNDYyOTliIiwiZXhwIjoxOTIyMjcwODE3LCJpZGVudGl0eSI6ImRldi5nYXJpYWphZ3J1dGlAYWFkaGFhcmFwaS5pbyIsImZyZXNoIjpmYWxzZSwidHlwZSI6ImFjY2VzcyIsInVzZXJfY2xhaW1zIjp7InNjb3BlcyI6WyJyZWFkIl19fQ.6YCuyDhaFrotMFVRycJ12fqd8DLfNrVIhv4Ex5sSAuA'
                            },
                            data: data
                        };

                        axios(config2).then(async response => {
                            if (response.status === 200) {
                                //Store in S3
                                //Update User
                                let urls = await Promise.all(
                                    files.map(async file => {
                                        try {
                                            if (file.type) {
                                                let datas = { fileType: "image/jpeg" }
                                                var config = {
                                                    method: 'post',
                                                    url: process.env.REACT_APP_SERVER + '/file/vehicleImages',
                                                    data: datas
                                                };
                                                let data = await axios(config);
                                                let d = await axios.put(data.data.url, file, {
                                                    headers: {
                                                        'Content-Type': file.type,
                                                    },
                                                });
                                                return data.data.url ? { path: data.data.url.split('?')[0] } : null
                                            } else return null;

                                        } catch (err) {
                                            return null
                                        }
                                    })
                                )
                                console.log(urls)
                                axios.put(process.env.REACT_APP_SERVER + '/auth/users/' + props.user._id,
                                    {
                                        aadhaar: aadhar,
                                        aadhaarUrl: urls[0].path
                                    },
                                    { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } }
                                )
                                    .then(response => {
                                        console.log(response.data)
                                        localStorage.setItem('user', JSON.stringify(response.data))
                                        setLoading(false)
                                        props.login(response.data);
                                        sendSMS(response.data);
                                        props.handleClose();

                                    }).catch(err => {
                                        console.log(err)
                                        props.openSnackbar('Failed to verify user. Please try again later!', 'error')
                                        setLoading(false)
                                    })
                            }
                        }).catch(err => {
                            console.log(error.response);
                            props.openSnackbar("Invalid Aadhar Card Photo Attached!", "error");
                            props.handleClose()
                            setLoading(false)
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                    props.openSnackbar("Invalid Aadhar Card Photo Attached!", "error");
                    props.handleClose()
                    setLoading(false)
                });

        } else {
            props.openSnackbar('Attaching Aadhar Photo is Mandatory!', 'error')
        }

    }

    let step1, step2;
    step === 1 ? step1 = classes.active : step2 = classes.active
    step === 2 ? step1 = classes.verified : step1 = classes.active

    return (
        <Dialog open={props.open} onClose={props.handleClose}
            style={{ maxWidth: 500, margin: 'auto' }}>
            {/* <DialogTitle>Register</DialogTitle> */}
            <DialogContent>
                {step === 3 ?
                    null :
                    <Breadcrumbs separator={<NavigateNext fontSize="small" />}>
                        <p className={step1 + " mb-0"}>Step 1 {step === 2 ? <CheckCircleIcon color='primary' fontSize='small' /> : null}</p>
                        <p className={step2 + ' mb-0'}>Step 2</p>
                    </Breadcrumbs>}
                {step === 1 ?
                    <Grid container spacing={3} style={{ paddingTop: '10px' }}>
                        {fields.map(field => {
                            return (
                                <Grid item xs={field.xs}>
                                    {field.name === 'mobileNo' ?
                                        <><PhoneInput
                                            // disableDropdown
                                            // onlyCountries={['om']}
                                            // disabled={true}
                                            country={"in"}
                                            placeholder={"Enter Your mobile Number"}
                                            value={formdata.mobileNo}
                                            onChange={mobileNo => {
                                                setFormdata({ ...formdata, mobileNo })
                                                setError({})
                                            }}
                                            enableSearch={true}
                                            disableSearchIcon={true}
                                            inputProps={{
                                                onBlur: checkUnique
                                            }}
                                            inputStyle={{
                                                backgroundColor: "#EEF5EE",
                                                border: "none",
                                                color: "rgb(56,56,56)",
                                                fontSize: "18px",
                                                width: "100%",
                                                border: "1px solid #4CAF50",
                                                boxShadow: "none",
                                                borderRadius: "2px",
                                                paddingTop: '10px',
                                                paddingBottom: '10px',
                                                height: 50
                                            }}
                                            buttonStyle={{
                                                backgroundColor: "transparent",
                                                border: "none",
                                            }}
                                        />
                                            <p className={classes.error}>{error.mobileNo}</p></> :
                                        <TextField
                                            key={field.name}
                                            style={{
                                                backgroundColor: '#EEF5EE',
                                                border: 'none'
                                            }}
                                            onBlur={field.onBlur ? checkUnique : null}
                                            variant='outlined'
                                            fullWidth
                                            label={field.label}
                                            name={field.name}
                                            onChange={handleChange}
                                            type={field.type}
                                            required={field.required}
                                            error={error[field.name]}
                                            value={formdata[field.name]}
                                            helperText={error[field.name]}
                                        />}
                                </Grid>
                            )
                        })}
                        <Grid item xs={12} justify='center'>
                            {loading ?
                                <Typography align='center'>
                                    <CircularProgress color='primary' />
                                </Typography>
                                :
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{
                                        borderRadius: '50px',
                                        width: '100%',
                                        color: 'white'
                                    }} onClick={handleStepOneSubmit}>Next</Button>}
                            <p className={classes.login}>Already have an account? <span onClick={props.handleLogin}>Log in</span></p>
                        </Grid>
                    </Grid> : step === 2 ? <Grid container spacing={3} style={{ paddingTop: 30, paddingBottom: 30 }}>
                        <Grid item xs={12} style={{ paddingBottom: 240 }}>
                            <Typography style={{ paddingBottom: 20, fontWeight: 600 }}>Upload your Aadhar for verification</Typography>
                            <Standard handleUpload={handleUpload} title='Browse Image for Aadhar Card Frontside' />
                        </Grid>
                        {/* <Grid item xs={12} style={{ paddingBottom: 160 }}>
                            <Standard handleUpload={handleUpload} title='Browse Image for Back of Aadhar' />
                        </Grid> */}
                        <Grid item xs={12} justify='center'>
                            {loading ?
                                <Typography align='center'>
                                    <CircularProgress color='primary' />
                                </Typography>
                                :
                                <Button
                                    variant='contained'
                                    color='primary'
                                    style={{
                                        borderRadius: '50px',
                                        width: '100%',
                                        color: 'white'
                                    }} onClick={handleStepTwoSubmit}>Submit Aadhar</Button>
                            }</Grid>
                    </Grid> : <div className='p-4 text-center'>
                            <img width={150} src={successSvg} />
                            <p className={classes.success}>Account Verified successfully</p>
                        </div>}

            </DialogContent>
        </Dialog>
    );
}

const mapStateToProps = state => {
    return {
        user: state.auth.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openSnackbar: (msg, severity) => dispatch(openSnackbar(msg, severity)),
        login: (user) => dispatch(login(user))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);

//if (files.length < 1) {
    //     props.openSnackbar('Aadhar image is mandatory!', 'error')
    // } else {
    //     setLoading(true)

    // }