import React, { Component } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { TabPanel, TabContext, TabList } from "@material-ui/lab";
import CarList from "./CarList";
const tabs = ["Popular, Featured, New Arrivals"];

class Samples extends Component {
  state = {
    selected: "1",
  };
  handleChange = (event, newValue) => {
    this.setState({
      ...this.state,
      selected: newValue,
    });
  };
  render() {
    return (
      <div className="text-center container p-5">
        <TabContext
          value={this.state.selected}
          style={{ justifyContent: "center" }}
        >
          <TabList
            onChange={this.handleChange}
            aria-label="simple tabs example"
          >
            <Tab label="Featured" value="1" />
            <Tab label="Popular" value="2" />
            <Tab label="New Arrival" value="3" />
          </TabList>
          <TabPanel value="1">
            <CarList />
          </TabPanel>
          <TabPanel value="2">
            <CarList />
          </TabPanel>
          <TabPanel value="3">
            <CarList />
          </TabPanel>
        </TabContext>
      </div>
    );
  }
}

export default Samples;
