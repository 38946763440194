import AttachFileIcon from '@material-ui/icons/AttachFile';

import React, { useState } from "react";

import { useDropzone } from "react-dropzone";

import classes from "./DropZone.module.css";

const FileUpload = (props) => {
  const [success, setSuccess] = useState(null);
  const [imageUrl, setUrl] = useState(null);
  const [toUpload, setToUpload] = useState([]);
  const [files, setFiles] = useState([]);

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDragOver: (e) => {
      e.preventDefault();
    },
    onDrop: async (acceptedFiles) => {

      let tempToUpload = [...toUpload, ...acceptedFiles];
      setToUpload(tempToUpload);
      props.handleUpload(tempToUpload);
      let modifiles = acceptedFiles.map((file) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });
      let tempFile = [...files, ...modifiles];
      setFiles(tempFile);
    },
  });

  const images = files.map((file, index) => (
    <>
      <img
        src={file && file.preview ? file.preview : file}
        className="img-thumbnail my-2"
        style={{
          margin: '20px',
          objectFit: 'contain',
        }}
        height={153}
        width={140}
        alt="preview"
      />
    </>
  ));

  return (
    <div>
      {images && images.length > 0 ? (
        <div elevation={0} style={{ display: "inline-block" }}>
          {images}
        </div>
      ) : (
          <div className={classes.file + " py-2"} {...getRootProps()}>
            {
              <>
                <input {...getInputProps()} />
                <div
                  className={classes.toUploadImages}
                >
                  {props.title} <span className={classes.icon}><AttachFileIcon color={'primary'} /></span>
                </div>
              </>
            }
          </div>
        )}
    </div>
  );
};

export default FileUpload;
