import React, { Component } from "react";
import classes from "./Testimonials.module.css";
import Testimonial from "./Testimonial";
import Carousel from "nuka-carousel";
import "./Slick/slick-theme.css";
import testimonial1 from "../../../Assets/testimonial1.jpeg";
import testimonial2 from "../../../Assets/testimonial2.jpeg";
import defaultDP from "../../../Assets/default.svg";
import archana from "../../../Assets/archana.jpg";
import man from "../../../Assets/man.jpg";

const data = [
  {
    blockquote:
      "Vehicle performance is good, Customer service is approachable, and the vehicle is in very good condition. I like this vehicle very much.",
    cite: "Ms.Archana - Actor.",
    img: archana,
  },
  {
    blockquote:
      "Buying experience is awesome and clear guidance provide for completing the formalities. Quality of the vehicle purchased is good with all necessary checks done by themselves before delivering the Car. Great Customer service. Overall had a fantastic experience buying a pre owned car.",
    cite: "Mr. Asif Rahman  - IT.",
    img: man,
  },
  {
    blockquote:
      "Economical and good in arranging finance for buying cars. No customer complaint so far",
    cite: "Franklin, owner Blessing Tours and Travels.",
    img: testimonial1,
  },
  {
    blockquote:
      "Honest and transparent business deal. They are very good in buying and selling of used cars",
    cite: "Mr. Anandan, used car dealer.",
    img: testimonial2,
  },
  {
    blockquote:
      "The best place to buy used cars in Chennai. The way of approching the customers(especially Shiva) is the key success point of them. Supporting 24*7, friendly workers.Just make a visit to the spot to know more. I'm totally satisfied with their service.1000% you can trust them.",
    cite: "Asok Kumar",
    img: defaultDP,
  },
  // {
  //   blockquote:
  //     "I had nice experience with them and i brought a indica car with reasonable price, I'm really happy with them....",
  //   cite: "Naveen Kumar",
  // },
  {
    blockquote:
      "Very open and flexible dealings....Good  customer satisfaction....bough Hyundai  santafe....Very happy to get this  vechicle  at low cost...",
    cite: "Mkarthick Uma",
    img: defaultDP,
  },
  {
    blockquote:
      "I bought a swift vdi from shiva cars.. it's working good.. reasonable price .. nice atmoshpire also.. especially I like the way of approaching the customers .. thank you shiva cars",
    cite: "Babu M",
    img: defaultDP,
  },
];
class Testimonials extends Component {
  state = {
    height: 0,
    width: 0,
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    let slidesToScroll = 2;
    if (this.state.width < 1000 && this.state.width > 750) {
      slidesToScroll = 2;
    } else if (this.state.width < 750) {
      slidesToScroll = 1;
    } else slidesToScroll = 2;

    return (
      <div className={classes.testimonials + " pb-5"}>
        <div className="container justify-content-left">
          <h1>Our Testimonials...</h1>
          <Carousel
            heightMode="max"
            autoplay={true}
            autoplayInterval={4000}
            slidesToShow={slidesToScroll}
            wrapAround={true}
            renderCenterLeftControls={null}
            renderCenterRightControls={null}
            // transitionMode='scroll3d'
            pauseOnHover={false}
          >
            {data.map((testimonial, index) => {
              return (
                <Testimonial
                  quote={testimonial.blockquote}
                  cite={testimonial.cite}
                  img={testimonial.img}
                  key={index}
                />
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Testimonials;
