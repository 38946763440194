import React, { Component } from "react";
import axios from "axios";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import classes from "./Summary.module.css";
import { Link, withRouter } from "react-router-dom";
import Slideshow from "../../Components/Slideshow/Slideshow";
import Form from "./Form/Form";
import VehicleDescription from "./VehicleDescription/VehicleDescriptionTest";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { connect } from "react-redux";
import Register from '../QuickBuy/Register/Register';

class Summary_test extends Component {
  state = {
    vehicle: null,
    vehicleImages: [],
    tabvalue: 0,
    loading: false,
  };

  componentDidMount() {
    if (!localStorage.getItem('token')) {
      this.props.history.push('/quick_buy')
    }
    window.scrollTo(0, 0);
    initGA("UA-176156592-1");
    PageView();
    this.setState({
      ...this.state,
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_SERVER +
        "/v1/quickbuy/" +
        this.props.match.params.id
      )
      .then((response) => {
        console.log(response.data);
        if (
          response.data.brand + "_" + response.data.model !==
          this.props.match.params.makemodel
        ) {
          this.props.history.push("/");
        } else {
          this.setState({
            ...this.state,
            loading: false,
            vehicle: response.data,
            vehicleImages: response.data.vehicleImages,
          });
        }
      })
      .catch((err) => {
        this.props.history.push("/quick_buy");
        console.log(err);
      });
  }

  handleTabChange = (e, newvalue) => {
    this.setState({
      ...this.state,
      tabvalue: newvalue,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };

  handleQuickBuyClick = () => {
    if (!this.props.user.isVerified && this.props.user.aadhaarUrl === null) {
      this.setState({ ...this.state, open: true })
    } else {
      this.props.history.push(
        "/payment/" +
        this.state.vehicle.brand +
        "_" +
        this.state.vehicle.model +
        "/" +
        this.state.vehicle._id
      )
    }
  }

  render() {
    return (
      <div>
        <Header />
        <div className={classes.summary}>
          <div className="container-fluid pt-2">
            <div
              className={classes.backButton + " text-left py-4 px-lg-4"}
              style={{ color: "grey" }}
              onClick={this.goBack}
            >
              <ArrowBackIcon color="primary" />
              Go Back
            </div>
            <div className="row px-lg-5 pb-lg-3">
              <div className="col-lg-6 pl-lg-0">
                {this.state.loading ? (
                  <Skeleton variant="rect" width="100%" height="100%" />
                ) : (
                    <Slideshow data={this.state.vehicleImages} />
                  )}{" "}
              </div>
              <div
                className="mx-3 p-0 mx-lg-0 col-lg-6 text-left"
                style={{ backgroundColor: "white", boxShadow: '0px 1px 10px #00000029' }}              >
                <div className={classes.triangle}></div>
                {this.state.vehicle && !this.state.loading ? (
                  <>
                    <Link
                      to={
                        "/payment/" +
                        this.state.vehicle.brand +
                        "_" +
                        this.state.vehicle.model +
                        "/" +
                        this.state.vehicle._id
                      }
                    >
                      <div className={`${classes.title} pt-3 pl-4`}>
                        {this.state.vehicle.brand +
                          " " +
                          this.state.vehicle.model +
                          " " +
                          this.state.vehicle.variant}
                      </div>
                    </Link>
                    <div className={`${classes.stats} pl-3`}>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.regYear}
                      </span>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.kmDriven} {"km"}
                      </span>
                      <span className={classes.borderRight + " px-2"}>
                        {this.state.vehicle.city}
                      </span>
                      <span className="px-2">
                        {this.state.vehicle.fuelType}
                      </span>
                    </div>

                    <div className={`${classes.price} p-4`}>
                      {/* <span>PRICE</span> */}
                      <h3>{Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })
                        .format(this.state.vehicle.sellCost)
                        .replace(/\.00/g, "")}</h3>
                    </div>

                  </>
                ) : (
                    <div>
                      <Skeleton width="80%" />
                      <Skeleton width="30%" />
                      <Skeleton width="50%" />
                    </div>
                  )}
                {this.state.vehicle ? (

                  <div className="row m-0">
                    <div className={classes.whiteCont + " w-100"}>
                      <VehicleDescription vehicle={this.state.vehicle} />
                      <p className={`${classes.description} pl-4`}>
                        {this.state.vehicle.description}
                      </p>
                      <div className="col-sm-12 col-xl-12 mb-5 text-center">
                        <button onClick={this.handleQuickBuyClick} className="btn-success btn w-75 rounded-pill">Quick Buy</button>
                      </div>

                    </div>
                  </div>
                ) : null}

              </div>
            </div>
          </div>
        </div>
        <Footer />

        {this.state.open ?
          <Register
            open={this.state.open}
            handleClose={() => this.setState({ ...this.state, open: !this.state.open })}
          />
          : null}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}

export default withRouter(connect(mapStateToProps)(Summary_test));
