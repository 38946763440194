import React, { Component } from "react";
import classes from "./Filter.module.css";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";

import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";
class Filter extends Component {
  state = {
    collapsed: false,
    searchInput: "",
  };

  toggleCollapse = () => {
    this.setState({
      ...this.state,
      collapsed: !this.state.collapsed,
    });
  };

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      ...this.state,
      searchInput: e.target.value,
    });
  };

  render() {
    let collapseClass = "d-block";
    collapseClass = this.state.collapsed ? "d-none" : "d-block";
    let data = this.props.data.filter(
      (item) =>
        item.toUpperCase().indexOf(this.state.searchInput.toUpperCase()) !== -1
    );

    return (
      <div className="text-left px-3 py-2">
        <div onClick={this.toggleCollapse} className={classes.title}>
          <FormattedMessage
            id="filters.title.searchBy"
            defaultMessage="Search By"
            description="Search By"
          />{" "}
          {this.props.title}
          <span style={{ float: "right" }}>
            {this.state.collapsed ? (
              <AddIcon fontSize="small" />
            ) : (
              <RemoveIcon fontSize="small" />
            )}
          </span>
        </div>
        <div className={collapseClass}>
          <div className={this.props.searchClass + " " + classes.search}>
            <input
              onChange={this.handleChange}
              type="text"
              className="form-control mb-3"
              placeholder={this.props.intl.formatMessage({
                id: "filter.placeholder.type-to-search",
              })}
            />
          </div>
          <div style={{ maxHeight: "100px", overflowY: this.props.overflowY }}>
            {data.map((item) => {
              let checked = this.props.appliedFilters.some(
                (filter) => item === filter
              );
              return (
                <label className={classes.containers} key={item}>
                  {item}
                  <input
                    checked={checked}
                    type="checkbox"
                    onClick={(event, obj) =>
                      this.props.onCheckboxClicked(event, {
                        name: item,
                      })
                    }
                  />
                  <span className={classes.checkmark}></span>
                </label>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Filter);
