import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import axios from "axios";
import * as formValidations from "../../../Components/FormValidation/FormValidation";
import classes from "../Summary.module.css";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";
class Form extends Component {
  state = {
    name: "",
    email: "",
    mobile: "",
    enquiry: "",
    errorJson: {},
    success: null,
  };

  handleChange = (event) => {
    let stateObj = this.state;
    stateObj[event.target.name] = event.target.value;
    this.setState({
      ...stateObj,
      errorJson: {},
    });
  };

  formValidation = () => {
    let errorJson = {};
    let errors = false;
    if (formValidations.emptyValidation(this.state.name)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.name-empty",
      });

      errorJson.nameError = errorMessage;
      errors = true;
    }
    if (this.state.mobile.length !== 10) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.mobile-10-digit",
      });

      errorJson.mobileError = errorMessage;
      errors = true;
    }

    if (!formValidations.validateEmail(this.state.email)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "sell-page.error.invalidEmail",
      });
      errorJson.emailError = errorMessage;
      errors = true;
    }
    this.setState({
      ...this.state,
      errorJson,
    });
    return errors;
  };

  onSubmit = () => {
    if (!this.formValidation()) {
      let data = {
        enquiryMessage: this.state.enquiry,
        enquiryType: "buying",
        email: this.state.email,
        contactNo: "+91" + this.state.mobile,
        vehicleId: this.props.vehicleId,
        contactName: this.state.name,
      };
      console.log(data);

      axios
        .post(process.env.REACT_APP_SERVER + "/enquiry", data)
        .then((response) => {
          let success = this.props.intl.formatMessage({
            id: "sell-page.success.message",
          });

          this.setState({
            ...this.state,
            success: success,
            name: "",
            email: "",
            mobile: "",
            enquiry: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      console.log(this.state.errorJson);
    }
  };

  render() {
    return (
      <div className="mt-4 mx-lg-5 px-lg-5">
        <div className="py-2">
          <TextField
            inputProps={{
              maxLength: 500,
            }}
            value={this.state.name}
            required
            error={this.state.errorJson.nameError}
            helperText={this.state.errorJson.nameError}
            name="name"
            onChange={this.handleChange}
            color="primary"
            label={this.props.intl.formatMessage({
              id: "sell-page.name.label",
            })}
            variant="outlined"
            fullWidth
            size="small"
          />
        </div>
        <div className="py-2">
          <TextField
            value={this.state.email}
            required
            type="email"
            error={this.state.errorJson.emailError}
            helperText={this.state.errorJson.emailError}
            name="email"
            onChange={this.handleChange}
            color="primary"
            label={this.props.intl.formatMessage({
              id: "sell-page.email-id.label",
            })}
            variant="outlined"
            fullWidth
            size="small"
          />
        </div>
        <div className="py-2">
          <TextField
            inputProps={{
              maxLength: 10,
            }}
            value={this.state.mobile}
            type="tel"
            required
            error={this.state.errorJson.mobileError}
            helperText={this.state.errorJson.mobileError}
            name="mobile"
            onChange={this.handleChange}
            color="primary"
            label={this.props.intl.formatMessage({
              id: "sell-page.mobile-number.label",
            })}
            variant="outlined"
            fullWidth
            size="small"
          />
        </div>
        <div className="py-2">
          <TextField
            inputProps={{
              maxLength: 500,
            }}
            rows={3}
            value={this.state.enquiry}
            error={this.state.errorJson.enquiryError}
            helperText={`${this.state.enquiry.length}/${500}`}
            name="enquiry"
            onChange={this.handleChange}
            color="primary"
            multiline
            label={this.props.intl.formatMessage({
              id: "sell-page.enquiry-message.label",
            })}
            variant="outlined"
            fullWidth
            size="small"
          />
        </div>
        <div className="py-2">
          <Button
            fullWidth
            color="primary"
            variant="contained"
            onClick={this.onSubmit}
          >
            <FormattedMessage
              id="sell-page.submit-enquiry-button"
              defaultMessage="Submit an enquiry"
              description="Submit an enquiry"
            />
          </Button>
        </div>
        {this.state.success ? (
          <div className={classes.success + " py-2"}>{this.state.success}</div>
        ) : null}
      </div>
    );
  }
}

export default injectIntl(Form);
