import React, { Component } from "react";
import classes from "./SearchList.module.css";
import axios from "axios";
import { vehicleCalculator } from "./VehicleCalculator";
import { Link, withRouter } from "react-router-dom";
import { Rating } from "@material-ui/lab";
import Card from "../../../Components/Preloader/Card";
import Guarantee from './guarantee.svg';
import milometer from './milometer.svg';
import automobile from './automobile-salesman.svg';
import Group1803 from './Group1803.svg';
import insurance from './insurance.svg';
import Group1912 from './Group1912.svg';
import icon_star from './icon_start.svg';
import icon_star_half from './Icon_star_half.svg';
import Register from '../../QuickBuy/Register/Register';
import { connect } from "react-redux";

class SearchList_test extends Component {
  state = {
    vehicles: [],
    loading: false,
    open: false
  };

  componentDidMount() {
    this.fetchCars();
  }

  fetchCars = (make, model) => {
    this.setState({
      ...this.state,
      loading: true,
    });
    let url = process.env.REACT_APP_SERVER + "/v1/quickbuy";
    axios
      .get(url)
      .then((response) => {
        this.setState({
          ...this.state,
          vehicles: response.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleQuickBuyClick = (vehicle) => {
    if (!this.props.user.isVerified && this.props.user.aadhaarUrl === null) {
      this.setState({ ...this.state, open: true })
    } else {
      this.props.history.push(
        "/payment/" +
        vehicle.brand +
        "_" +
        vehicle.model +
        "/" +
        vehicle._id
      )
    }
  }

  render() {
    let vehicles = this.state.vehicles;
    vehicles = vehicleCalculator(
      vehicles,
      this.props.appliedMakeModel,
      this.props.appliedFuelType,
      this.props.appliedNoOwners,
      this.props.appliedPriceRange,
      this.props.city
    );
    return (
      <div className="row p-3">
        {this.state.loading ? (
          <>
            <Card />
            <Card />
            <Card />
          </>
        ) : (
          vehicles.map((vehicle) => {
            return (
              <div className="py-2 col-sm-12 col-xl-12" key={vehicle._id}>
                <div className={classes.myCard + " card"}>
                  <div className="row m-0" style={{ background: "#C4C4C4" }}>
                    <div className="col-sm-4 col-xl-4 my-auto">
                      <div className="row">
                        <div className="">
                          <img
                            className={classes.card_img}
                            src={vehicle.vehicleImages[0]}
                            alt="car"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8 col-xl-8 pr-0 bg-white">
                      <div className="d-flex justify-content-between">
                        <div className={classes.cars_label + " m-3"}>
                          <span
                            style={{
                              fontSize: "26px",
                              textAlign: "left"
                            }}
                          >
                            {
                              vehicle.brand +
                              " " +
                              vehicle.model +
                              " " +
                              vehicle.variant
                            }
                          </span>
                        </div>
                        <div className="">
                          <div className={classes.btn}>
                            <p>{vehicle.tags && vehicle.tags[0]}</p>
                          </div>
                          {/* <img
                              src={Group1912}
                              style={{
                                float: "right",
                              }}
                            /> */}
                        </div>
                      </div>

                      <div className="d-flex justify-content-between mx-auto">
                        <span className={classes.cars_label + ' pl-3'}
                          style={{
                            fontSize: "16px",
                            color: '#A9A9A9'
                          }}
                        >{vehicle.color} | {vehicle.fuelType} | {vehicle.transmissionType} | {vehicle.manfYear}</span>

                      </div>
                      <div className={classes.price_tag}>
                        {Intl.NumberFormat("en-IN", {
                          style: "currency",
                          currency: "INR",
                        })
                          .format(vehicle.sellCost)
                          .replace(/\.00/g, "")}
                      </div>


                      <div className="row">
                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <img src={milometer} />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_static_label}>KMs Driven</span><br /><span className={classes.cars_label}>{vehicle.kmDriven}</span>
                          </div>
                        </div>

                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <img src={Guarantee} />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_static_label}>Warranty</span><br /><span className={classes.cars_label}>{vehicle.warranty}</span>
                          </div>
                        </div>

                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <img src={automobile} />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_static_label}>Previous Owners</span><br /><span className={classes.cars_label}>{vehicle.noOfOnwers}</span>
                          </div>
                        </div>
                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <img src={Group1803} />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_static_label}>180 checkpoints</span><br /><span className={classes.cars_label}>{vehicle.fuelType}</span>
                          </div>
                        </div>

                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <img src={insurance} />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_static_label}>Insurance</span><br /><span className={classes.cars_label}>{vehicle.insurance}</span>
                          </div>
                        </div>

                        <div className="col-sm-4 col-6 d-flex">
                          <div className="my-auto">
                            <Rating
                              style={{ color: "#4caf50" }}
                              size="small"
                              name="Vehicle Rating"
                              value={parseInt(vehicle.fitnessUpto)}
                              readOnly
                            />
                          </div>
                          <div className="my-auto p-2">
                            <span className={classes.cars_label}>{vehicle.fitnessUpto}/5</span>
                          </div>
                        </div>
                      </div>

                      <div className="col-sm-12 d-flex justify-content-between mx-auto">
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-xl-12 my-4">
                          <button className={classes.quick_buy + " btn-success btn rounded-pill"} onClick={() => this.handleQuickBuyClick(vehicle)}>Quick Buy</button>
                        </div>
                        <div className="col-sm-12 col-xl-12 mb-4">
                          <Link
                            to={
                              "/quick_buy/summary/" +
                              vehicle.brand +
                              "_" +
                              vehicle.model +
                              "/" +
                              vehicle._id
                            }
                            className="text-success"
                          >
                            Show all features {'>'}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.open ?
                  <Register
                    open={this.state.open}
                    handleClose={() => this.setState({ ...this.state, open: !this.state.open })}
                  />
                  : null}
              </div>
            );
          })
        )}
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     user: state.auth.user
//   }
// }

export default SearchList_test