import React, { Component } from "react";
import axios from "axios";
import Header from "../../Components/Navbars/Header/Header";
import Footer from "../../Components/Navbars/Footer/Footer";
import classes from "./Summary.module.css";
import Slideshow from "../../Components/Slideshow/Slideshow";
import Form from "./Form/Form";
import VehicleDescription from "./VehicleDescription/PaymentDescriptionTest";
import { PageView, initGA } from "../../Components/Tracking/Tracking";
import { Skeleton } from "@material-ui/lab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Group1884 from './Group1884.svg';
class Summary_test extends Component {
  state = {
    vehicle: null,
    vehicleImages: [],
    tabvalue: 0,
    loading: false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    initGA("UA-176156592-1");
    PageView();
    this.setState({
      ...this.state,
      loading: true,
    });

    axios
      .get(
        process.env.REACT_APP_SERVER +
          "/v1/quickbuy/" +
          this.props.match.params.id
      )
      .then((response) => {
        console.log(response.data);
        if (
          response.data.brand + "_" + response.data.model !==
          this.props.match.params.makemodel
        ) {
          this.props.history.push("/");
        } else {
          this.setState({
            ...this.state,
            loading: false,
            vehicle: response.data,
            vehicleImages: response.data.vehicleImages,
          });
        }
      })
      .catch((err) => {
        this.props.history.push("/");
        console.log(err);
      });
  }

  handleTabChange = (e, newvalue) => {
    this.setState({
      ...this.state,
      tabvalue: newvalue,
    });
  };

  goBack = () => {
    this.props.history.goBack();
  };
  render() {
    return (
      <div>
        <Header />
        <div className={`${classes.summary} bg-white`}>
          <div className="container-fluid pt-2">
            <div
              className={classes.backButton + " text-left py-4 px-lg-4"}
              style={{ color: "grey" }}
              onClick={this.goBack}
            >
              <ArrowBackIcon color="primary"/>
              Go Back
            </div>
            <div className="row">
              <div className="col-lg-6 pl-lg-0 bg-white p-5">
                <img src={Group1884} className="w-100" />
              </div>
              <div
                className="mx-lg-0 col-lg-6 p-3 text-left p-5"
                style={{ backgroundColor: "white" }}
              >
                <div className={classes.triangle}></div>
                {this.state.vehicle && !this.state.loading ? (
                  <>
                    <div className={classes.title}>
                      {this.state.vehicle.brand +
                        " " +
                        this.state.vehicle.model +
                        " " +
                        this.state.vehicle.variant}
                    </div>
                    
                    
                    <div className={classes.price}>
                      {/* <span>PRICE</span> */}
                      <h3>{Intl.NumberFormat("en-IN", {
                        style: "currency",
                        currency: "INR",
                      })
                        .format(this.state.vehicle.sellCost)
                        .replace(/\.00/g, "")}</h3>
                    </div>
                    
                  </>
                ) : (
                  <div>
                    <Skeleton width="80%" />
                    <Skeleton width="30%" />
                    <Skeleton width="50%" />
                  </div>
                )}
                {this.state.vehicle ? (
                <div className="col-xl-7 px-0 pr-xl-3">
                  <div className={classes.whiteCont}>
                    <p className={classes.description}>
                    Please find the cars4all bank account for making the payment
                    </p>
                    <VehicleDescription vehicle={this.state.vehicle} />
                  </div>
                </div>
              ) : null}
                
              </div>
            </div>
            {/* <div className="row pt-3 pt-lg-0 px-3 px-lg-5 pb-5 text-left">
              {this.state.vehicle ? (
                <div className="col-xl-7 px-0 pr-xl-3">
                  <div className={classes.whiteCont + " p-3"}>
                    <p className={classes.description}>
                      {this.state.vehicle.description}
                    </p>
                    <VehicleDescription vehicle={this.state.vehicle} />
                  </div>
                </div>
              ) : null}
            </div> */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Summary_test;
