import * as actionTypes from "./actionTypes"

export const login = (user) => {
    return dispatch => {
        dispatch({
            type: actionTypes.LOGIN_SUCCESS,
            user: user
        })
    }
}

export const logout = () => {
    return dispatch => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        dispatch({ type: actionTypes.LOGIN_SUCCESS, user: null });
        window.location.reload()
    }
}

export const check_auth_state = (query) => {
    return dispatch => {
        let token = localStorage.getItem("token");
        let user = JSON.parse(localStorage.getItem("user"))
        if (user && token) {
            dispatch(login(user))
        }
    }
}
