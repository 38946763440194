import React, { Component } from "react";
import axios from "axios";
import Filter from "./Filter/Filter";
import Dropdown from "../../../Components/Navbars/Dropdown/Dropdown";
import queryString from "query-string";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";

class Filters extends Component {
  state = {
    makes: [],
    models: [],
    appliedMakeModel: [],
  };

  render() {
    return (
      <>
        <Filter
          title={this.props.intl.formatMessage({
            id: "filters.title.makeOrModel",
          })}
          data={this.props.models}
          overflowY="scroll"
          onCheckboxClicked={this.props.onClickMakeModel}
          appliedFilters={this.props.appliedMakeModel}
        />
        <Filter
          searchClass="d-none"
          title={this.props.intl.formatMessage({
            id: "filters.title.fuelType",
          })}
          overflowY="scroll"
          data={["Diesel", "Petrol", "CNG", "LPG", "Electric", "Hybrid"]}
          onCheckboxClicked={this.props.onClickFuelModel}
          appliedFilters={this.props.appliedFuelType}
        />
        <Filter
          searchClass="d-none"
          overflowY="scroll"
          title={this.props.intl.formatMessage({
            id: "filters.title.priceRange",
          })}
          data={[
            "Rs0 - Rs1,50,000",
            "Rs1,50,001 - Rs3,00,000",
            "Rs3,00,001 - Rs5,00,000",
            "Rs5,00,001 - Rs7,00,000",
            "Rs7,00,001 - Rs10,00,000",
            "Rs10,00,001 and above",
          ]}
          onCheckboxClicked={this.props.onClickPriceRange}
          appliedFilters={this.props.appliedPriceRange}
        />
        <Filter
          searchClass="d-none"
          overflowY="scroll"
          title={this.props.intl.formatMessage({
            id: "filters.title.noOfOwners",
          })}
          data={["1", "2", "3", "4 and above"]}
          appliedFilters={this.props.appliedNoOwners}
          onCheckboxClicked={this.props.onClickNoOwners}
        />
      </>
    );
  }
}

export default injectIntl(Filters);
