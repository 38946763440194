import React, { Component } from "react";
import classes from "./WhyChooseUs.module.css";
import SecurityIcon from "@material-ui/icons/Security";
import { Link } from "react-router-dom";
import icon1 from "../../../Assets/Icons/icon-certified1.png";
import icon3 from "../../../Assets/Icons/icon-exchange2.png";
import icon2 from "../../../Assets/Icons/icon-lowcost1.png";
import icon4 from "../../../Assets/Icons/icon-parking2.png";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";

class WhyChooseUs extends Component {
  render() {
    return (
      <div className={classes.whyChooseUs}>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mb-5 align-self-center">
              <div className="main-title">
                <h1>
                  {" "}
                  <FormattedMessage
                    id="about-us.title"
                    defaultMessage="About Us"
                    description="About Us Title"
                  />
                </h1>
                <p>
                  <FormattedMessage
                    id="about-us.text"
                    defaultMessage="About Us"
                    description="About Us Infof"
                  />
                </p>
                <Link to="/contact_us" className={classes.contactButton}>
                  <FormattedMessage
                    id="about-us.contact"
                    defaultMessage="Contact Us"
                    description="About Us Contact"
                  />
                </Link>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row text-left">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="media advantages-box-3">
                    <div className={classes.icon} style={{ color: "#4CAF50 " }}>
                      <img
                        src={icon1}
                        style={{ width: "50px", height: "50px" }}
                        alt="Certified Cars"
                      />
                    </div>
                    <div className="detail media-body align-self-center">
                      <h5>
                        <FormattedMessage
                          id="about-us.certified-cars.title"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />
                      </h5>
                      <p>
                      <FormattedMessage
                          id="about-us.certified-cars.description"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />                   
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="media advantages-box-3">
                    <div className={classes.icon} style={{ color: "#4CAF50 " }}>
                      <img
                        src={icon2}
                        style={{ width: "50px", height: "50px" }}
                        alt="Certified Cars"
                      />{" "}
                    </div>
                    <div className="detail media-body align-self-center">
                      <h5>
                      <FormattedMessage
                          id="about-us.affordable.title"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />    
                        </h5>
                      <p>
                      <FormattedMessage
                          id="about-us.affordable.description"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />    
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="media advantages-box-3">
                    <div className={classes.icon} style={{ color: "#4CAF50 " }}>
                      <img
                        src={icon3}
                        style={{ width: "50px", height: "50px" }}
                        alt="Certified Cars"
                      />{" "}
                    </div>
                    <div className="detail media-body align-self-center">
                      <h5>
                        
                      <FormattedMessage
                          id="about-us.exchange-facility.title"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />    
                        </h5>
                      <p>
                      <FormattedMessage
                          id="about-us.exchange-facility.description"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />   
                        
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="media advantages-box-3">
                    <div className={classes.icon} style={{ color: "#4CAF50 " }}>
                      <img
                        src={icon4}
                        style={{ width: "50px", height: "50px" }}
                        alt="Certified Cars"
                      />{" "}
                    </div>
                    <div className="detail media-body align-self-center">
                      <h5>
                      <FormattedMessage
                          id="about-us.parking-sale.title"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />  
                        </h5>
                      <p>
                      <FormattedMessage
                          id="about-us.parking-sale.description"
                          defaultMessage="Certified Cars"
                          description="Certified Cars"
                        />  
                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhyChooseUs;
