import React, { Component } from "react";
import classes from "./Landing.module.css";
import Dropdown from "../../../Components/Navbars/Dropdown/Dropdown";
import Select from "react-select";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import { injectIntl, intlShape } from "react-intl";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #eee",
    color: state.isSelected ? "white" : "black",
    background: state.isSelected ? "#4CAF50 " : "white",
    cursor: "pointer",
    fontSize: "14px",
    "&:hover": { backgroundColor: "#4CAF50 ", color: "white" },
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";
    return { ...provided, opacity, transition };
  },
  control: (provided, state) => {
    const textAlign = "center";
    const borderColor = "#ced4da";
    const borderRadius = "0";
    const width = "100%";
    const fontSize = "14px";
    return {
      ...provided,
      textAlign,
      borderColor,
      borderRadius,
      width,
      fontSize,
    };
  },
  indicatorSeparator: (styles) => ({ display: "none" }),
};

let options = [{ value: "OPtion1", label: "Option1" }];

class Landing extends Component {
  state = {
    type: "Buy",
    selectedTab: "Buy",
    makeModel: [],
    selectedBrand: null,
    selectedModel: null,
    hovered: false,
  };

  componentDidMount() {
    this.getMakeModels();
  }

  componentDidUpdate(prevProps) {
    if (this.props.city !== prevProps.city) {
      this.getMakeModels();
    }
  }

  getMakeModels = () => {
    axios
      .get(process.env.REACT_APP_SERVER + "/make?city=" + this.props.city)
      .then((response) => {
        if (response.status === 200) {
          let makeModel = response.data;
          this.setState({
            ...this.state,
            makeModel,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = (event, type) => {
    if (type === "brand") {
      this.setState({
        ...this.state,
        selectedBrand: event.value,
        selectedModel: null,
      });
    } else {
      this.setState({
        ...this.state,
        selectedModel: event.value,
      });
    }
  };

  grenerateOptions = (brand) => {
    if (brand) {
      let makeModel = this.state.makeModel.find((item) => item.brand === brand);
      let models = [];
      makeModel.models.map((model) => {
        models.push({ value: model, label: model });
      });
      return models;
    } else {
      let models = [];
      this.state.makeModel.map((make) => {
        make.models.map((model) => {
          models.push({ value: model, label: model });
        });
      });
      return models;
    }
  };

  generateBrandOptions = () => {
    let makes = [];
    this.state.makeModel.map((make) => {
      makes.push({ value: make.brand, label: make.brand });
    });
    return makes;
  };

  // handleHover = (type) => {
  //   this.setState({
  //     hovered: type,
  //   });
  // };

  render() {
    let buyClass = null;
    let sellClass = null;
    this.state.hovered === false
      ? (buyClass = classes.selectedTab)
      : (sellClass = classes.selectedTab);

    let brandOption = this.generateBrandOptions();
    let modelOption = this.grenerateOptions(this.state.selectedBrand);
    let url = "/car_listings";
    if (this.state.selectedModel) {
      url =
        "/car_listings?make=" +
        this.state.selectedBrand +
        "&model=" +
        this.state.selectedModel;
    } else if (this.state.selectedBrand) {
      url = "/car_listings?make=" + this.state.selectedBrand;
    }

    return (
      <div className={classes.landing}>
        <div className={classes.introText}>
          <h1>
            CARS<span>4</span>ALL
          </h1>
          <p>
            <FormattedMessage
              id="landing.heading"
              defaultMessage="Search"
              description="Search"
            />
          </p>
          <div className={classes.tabs}>
            <div className={classes.tab + " " + buyClass}>
              <FormattedMessage
                id="landing.buy-car"
                defaultMessage="Buy Car"
                description="Buy Car"
              />
            </div>
            <Link to="/sell_car">
              <div
                className={classes.tab + " " + sellClass}
                // onMouseEnter={() => this.handleHover(true)}
                // onMouseLeave={() => this.handleHover(false)}
              >
                <FormattedMessage
                  id="landing.sell-car"
                  defaultMessage="Sell Car"
                  description="Sell Car"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className={classes.inputField + " row p-3"}>
          <div className="col-lg-12 py-1 px-0">
            <Select
              value={
                this.state.selectedBrand
                  ? {
                      value: this.state.selectedBrand,
                      label: this.state.selectedBrand,
                    }
                  : null
              }
              styles={customStyles}
              onChange={(e) => this.handleChange(e, "brand")}
              inputProps={{ readOnly: true }}
              placeholder={this.props.intl.formatMessage({ id: "brand" })}
              options={brandOption}
              //defaultInputValue='Pune'
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "#4CAF50 ",
                },
              })}
            />
          </div>
          <div className="col-lg-12 py-1 px-0">
            <Select
              value={
                this.state.selectedModel
                  ? {
                      value: this.state.selectedModel,
                      label: this.state.selectedModel,
                    }
                  : null
              }
              styles={customStyles}
              onChange={(e) => this.handleChange(e, "model")}
              inputProps={{ readOnly: true }}
              placeholder={this.props.intl.formatMessage({ id: "model" })}
              options={modelOption}
              //defaultInputValue='Pune'
              theme={(theme) => ({
                ...theme,
                borderRadius: 0,
                colors: {
                  ...theme.colors,
                  primary: "#4CAF50 ",
                },
              })}
            />
          </div>

          <div className="col-lg-12 py-1 px-0">
            <Link to={url}>
              <div className={"btn " + classes.goBtn}>
                <FormattedMessage
                  id="landing.search"
                  defaultMessage="Search"
                  description="Search"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Landing);
